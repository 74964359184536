import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import {
  FeatureFlag,
  FetchStatus,
  InternalScene,
  PatchOperation,
  SynthesisMarkupLanguage,
  Voice,
  VoiceProvider
} from "app/types";

import { useAppDispatch, useAppSelector } from "app/hooks";
import "app/components/editor/Editor.scss";
import { defineMessages, useIntl } from "react-intl";
import styled, { useTheme } from "styled-components";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { SceneVoiceStatus } from "app/store/thunks/analyticsEvents.thunk";
import { ListenStatus, voicesActions } from "app/store/slices/voices.slice";
import { scenesGlobalSelectors, voicesGlobalSelectors } from "app/store/adapters/adapters";
import { Dictionary } from "@reduxjs/toolkit";
import * as draftsSelectors from "app/store/selectorsV2/drafts.selectors";
import { scenesActions } from "app/store/slices/scenes.slice";
import useModal, { ModalName } from "app/hooks/useModal";
import ConditionalRender from "app/components/common/ConditionalRender";
import useDisplayUrls from "app/hooks/useDisplayUrls";
import TypeTextAnimationParagraph from "app/components/editor/scene/TypeTextAnimationParagraph";
import { fetchingStatus, getTextOutOfSML } from "app/utils/helpers";
import { RootState } from "app/store/store";
import LexicalEditor from "app/components/common/LexicalEditor/LexicalEditor";
import { getLimits } from "app/store/selectorsV2/workspaces.selectors";
import { LiveBlocksEventType, useBroadcastEvent } from "app/services/liveBlocksClient";
import scenesSelectors from "app/store/selectorsV2/scenes.selectors";
import { draftsActions } from "app/store/slices/drafts.slice";
import { PauseNode } from "app/components/common/LexicalEditor/nodes/PauseNode";
import { PronunciationNode } from "app/components/common/LexicalEditor/nodes/PronunciationNode";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import { VariableNode } from "app/components/common/LexicalEditor/nodes/VariableNode";
import { Button } from "@nextui-org/react";
import { Tooltip } from "antd";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ThemeMode } from "app/utils/theme";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";

const DEFAULT_SCROLLBAR_WIDTH = 8;
const DEFAULT_PADDING_RIGHT = 0;

const AbsoluteFlexColumn = styled(H1_FlexColumn)`
  top: 10px;
`;
const WaveImage = styled.img`
  width: 130px;
  height: 49px;
`;
export const StyledLexicalEditor = styled(LexicalEditor)`
  caret-color: ${({ theme }) => theme.gray11};
  width: 480px;
  font-size: 0.9375rem;
  font-weight: 400;
  font-family: Inter;
  line-height: 1.375rem;
  border: none !important;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 0;
  box-shadow: none;
  min-height: 66px;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
  color: ${({ theme }) => theme.gray11};
  opacity: ${(props: { $visible: boolean }) => (props.$visible ? 1 : 0)};
  transition: 0.3s all ease-in-out;
  > div {
    transition: 0.3s all ease-in-out;
    &:focus-within {
      outline: none;
    }
  }
`;

const messages = defineMessages({
  transcriptPlaceholder: {
    id: `scene.transcript-placeholder`,
    defaultMessage: `Scene transcript`
  },
  transcriptVoiceTooltip: {
    id: `scene.transcript-tooltip`,
    defaultMessage: `Apply voice on audio`
  }
});

export interface SceneMixedTaggedProps {
  onUseVoice: () => void;
  currentInnerText: string;
  viewOnly: boolean;
  hideToolbar: boolean;
  idx: number;
  scene: InternalScene;
  setInnerText: Dispatch<SetStateAction<string>>;
}

const SceneMixedTagged = ({
  idx,
  scene,
  setInnerText,
  onUseVoice,
  currentInnerText,
  viewOnly,
  hideToolbar
}: SceneMixedTaggedProps) => {
  const [isScrollbarVisible, setIsScrollbarVisible] = useState<boolean>(false);
  const [animatedText, setAnimatedText] = useState<boolean>(false);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { openModal } = useModal();
  const { displayUrls } = useDisplayUrls([scene.attributes?.media?.transcript?.url]);
  const broadcastEvents = useBroadcastEvent();
  const { sceneId: selectedSceneId } = useSelectedScene();
  const theme = useTheme();
  const flags = useFlags();

  const scenes = useAppSelector(scenesGlobalSelectors.selectAll);
  const draft = useAppSelector(draftsSelectors.currentDraft);
  const footerAudioPlayer = useAppSelector((state) => state.voices.footerAudioPlayer);
  const voices: Dictionary<Voice> = useAppSelector(voicesGlobalSelectors.selectEntities);
  const wandOverlaySceneId: string | undefined = useAppSelector(
    (state: RootState) => state.scenes.wandOverlaySceneId
  );
  const augmentedSceneStatus: FetchStatus = useAppSelector(
    (state: RootState) => state.scenes.augmentedSceneStatus
  );
  const generateTranscriptStatus: FetchStatus = useAppSelector(
    (state: RootState) => state.scenes.generateTranscriptStatus
  );
  const topicToDraftStatus: FetchStatus = useAppSelector(
    (state) => state.drafts.topicToDraftStatus
  );
  const revertDraftStatus: FetchStatus = useAppSelector((state) => state.drafts.revertDraftStatus);
  const segmentToSceneStatus: FetchStatus = useAppSelector(
    (state) => state.drafts.segmentToSceneStatus
  );

  const currentVoice: Voice | undefined = useAppSelector((state) =>
    scenesSelectors.getSceneVoiceBySceneId(state, scene.id)
  );
  const outsideChangeSML = useAppSelector((state) => state.scenes.outsideChangeSML);

  const limits = useAppSelector(getLimits);

  const scenesLeft = limits.scenes - scenes.length;
  const isSceneSelected = selectedSceneId === scene.id;

  const isSceneReadOnly =
    viewOnly ||
    topicToDraftStatus === fetchingStatus.loading ||
    revertDraftStatus === fetchingStatus.loading;

  const maxTranscriptLength = limits?.chars_per_scene as number;
  const voiceId = scene?.attributes.voice?.voice?.voice_id as string;
  const voiceData = voices[voiceId];
  const draftId = draft.id as string;
  const sceneId = scene.id as string;
  const transcriptUrl = scene.attributes?.media?.transcript?.url;
  const transcriptPreviewUrl = transcriptUrl ? displayUrls[transcriptUrl] : undefined;
  const isSupportedSpeechToSpeech =
    currentVoice?.provider === VoiceProvider.ElevenLabs ||
    currentVoice?.provider === VoiceProvider.respeecher;
  const isTranscriptUrlVoiceSelected =
    !!scene.attributes?.media?.transcript?.speech_to_speech?.enabled;

  const outsideChangeSmlContent = useMemo(() => {
    if (outsideChangeSML[sceneId]) {
      return outsideChangeSML[sceneId];
    }
    return undefined;
  }, [outsideChangeSML]);

  const isAiLoading = useMemo(
    () =>
      augmentedSceneStatus === fetchingStatus.loading ||
      generateTranscriptStatus === fetchingStatus.loading ||
      segmentToSceneStatus === fetchingStatus.loading,
    [augmentedSceneStatus, generateTranscriptStatus, segmentToSceneStatus]
  );

  const animatedTextPaddingRight = isScrollbarVisible
    ? DEFAULT_PADDING_RIGHT + DEFAULT_SCROLLBAR_WIDTH
    : DEFAULT_PADDING_RIGHT;

  const currentTextAsSML = useMemo(() => {
    const transcript = scene?.attributes.text?.transcript;
    const textNode = [
      {
        text: transcript?.text,
        version: 1,
        type: "text"
      }
    ];
    return transcript?.synthesis_markup_language?.nodes || (textNode as SynthesisMarkupLanguage[]);
  }, [scene?.attributes.text?.transcript]);
  useEffect(() => {
    if (wandOverlaySceneId === sceneId) {
      if (augmentedSceneStatus === fetchingStatus.succeeded) {
        setAnimatedText(true);
        dispatch(
          scenesActions.updateOutsideChangeSynthesisMarkupLanguage({
            [sceneId]: currentTextAsSML
          })
        );
      }
    }
  }, [augmentedSceneStatus, wandOverlaySceneId, sceneId]);

  useEffect(() => {
    if (wandOverlaySceneId === sceneId) {
      if (generateTranscriptStatus === fetchingStatus.succeeded) {
        setAnimatedText(true);
      }
    }
  }, [generateTranscriptStatus, wandOverlaySceneId, sceneId]);

  useEffect(() => {
    const element = document.querySelector(".text-editor-box");
    setIsScrollbarVisible(!!(element && element.scrollHeight > element.clientHeight));
  }, [animatedText]);

  const updateText = ({ json: advancedTranscript }: { json: SynthesisMarkupLanguage[] }) => {
    const transcript = getTextOutOfSML(advancedTranscript);
    const operations: PatchOperation[] = [
      {
        op: "replace",
        path: `attributes.text.transcript`,
        value: { synthesis_markup_language: { nodes: advancedTranscript }, text: transcript }
      }
    ];
    dispatch(
      googleEvents.editTranscript({
        length: Number(transcript.length),
        sceneId,
        sceneIndex: idx,
        language: voiceData?.local
      })
    );
    dispatch(voicesActions.turnOffFullAudioPlayed(draftId));
    dispatch(
      voicesActions.updateFooterAudioPlayer({
        url: footerAudioPlayer.url,
        status: ListenStatus.idle
      })
    );
    broadcastEvents({
      type: LiveBlocksEventType.sceneTextChanged,
      sceneId,
      sml: advancedTranscript
    });
    dispatch(
      scenesActions.patchSceneRequest({
        draftId,
        sceneId,
        operations
      })
    );
  };

  const onChangeInnerText = (text: string) => {
    dispatch(voicesActions.cleanFooterAudio());
    setInnerText(text);
  };

  const onRemoveInvalidChars = ({ json }: { json: SynthesisMarkupLanguage[] }) => {
    const text = getTextOutOfSML(json);
    dispatch(
      googleEvents.removeInvalidChars({
        sceneId,
        transcript: text
      })
    );
    dispatch(
      scenesActions.updateOutsideChangeSynthesisMarkupLanguage({
        [sceneId]: json
      })
    );
  };

  const onPaste = (text: string) => {
    dispatch(
      googleEvents.pasteTextInScene({
        selectedScene: {
          name: scene.name,
          id: sceneId,
          index: idx
        },
        transcriptText: text,
        transcriptLength: text.length
      })
    );
  };

  const onUploadAudio = () => {
    dispatch(
      googleEvents.useVoice({
        selectedScene: {
          name: scene.name,
          id: sceneId,
          index: idx
        },
        status: SceneVoiceStatus.replace
      })
    );
    openModal(ModalName.transcriptAudioUploadModal, {
      sceneId,
      draftId,
      name: scene.name,
      idx
    });
  };

  const onCancelAudioTranscript = () => {
    dispatch(voicesActions.cleanFooterAudio());
    dispatch(
      googleEvents.useVoice({
        selectedScene: {
          name: scene.name,
          id: sceneId,
          index: idx
        },
        status: SceneVoiceStatus.remove
      })
    );
    dispatch(
      scenesActions.patchSceneRequest({
        draftId,
        sceneId,
        operations: [{ op: "delete", path: `attributes.media.transcript` }],
        skipRePreview: true
      })
    );
  };

  const handleTO = () => {
    dispatch(draftsActions.updateSegmentToScenesStatusToFailure());
  };

  useEffect(() => {
    let segmentTO: NodeJS.Timeout | undefined = undefined;
    if (wandOverlaySceneId === sceneId && segmentToSceneStatus === fetchingStatus.loading) {
      segmentTO = setTimeout(handleTO, 1 * 60000); // A minute
    }

    if (
      wandOverlaySceneId === sceneId &&
      segmentToSceneStatus !== fetchingStatus.loading &&
      !!segmentTO
    ) {
      clearTimeout(segmentTO);
    }

    return () => {
      if (segmentTO) {
        clearTimeout(segmentTO);
      }
    };
  }, [segmentToSceneStatus, wandOverlaySceneId]);

  useEffect(() => {
    if (wandOverlaySceneId === sceneId && segmentToSceneStatus === fetchingStatus.failed) {
      dispatch(scenesActions.updateWandOverlaySceneId(undefined));
    }
  }, [segmentToSceneStatus]);

  const sendSegmentToScenes = () => {
    dispatch(
      googleEvents.splitTextOfferAccepted({
        sceneId,
        pastedText: ""
      })
    );

    dispatch(scenesActions.updateWandOverlaySceneId(sceneId));
    dispatch(draftsActions.segmentToScenesRequest({ sceneId }));
  };

  const onChangeTranscriptUrlVoiceSelection = (value: boolean) => {
    dispatch(voicesActions.cleanFooterAudio());
    dispatch(voicesActions.cleanVoicePreviewV2());
    const operations: PatchOperation[] = [
      {
        op: "replace",
        path: `attributes.media`,
        value: {
          transcript: {
            url: transcriptUrl,
            speech_to_speech: {
              enabled: value
            }
          }
        }
      }
    ];

    dispatch(
      scenesActions.patchSceneRequest({
        draftId,
        sceneId,
        operations,
        skipRePreview: true
      })
    );
  };

  return (
    <>
      <ConditionalRender condition={!!transcriptUrl}>
        <H1_FlexColumn flex="1" align="center">
          <AbsoluteFlexColumn position="absolute" alignSelf="center" align="center">
            <H1_FlexRow gap="20px" align="center">
              <WaveImage
                src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/waveform.svg"
                alt=""
              />
              <WaveImage
                src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/waveform.svg"
                alt=""
              />
            </H1_FlexRow>
            <H1_FlexRow gap="20px" align="center" justify="center">
              <ConditionalRender condition={!!transcriptPreviewUrl?.fileName}>
                <H1_FlexRow flex="0 1 200px" justify="center">
                  <H1_TextMiddle ellipsis color={theme.gray5}>
                    {transcriptPreviewUrl?.fileName}
                  </H1_TextMiddle>
                </H1_FlexRow>
              </ConditionalRender>
              <H1_FlexRow align="center" gap="10px">
                <ConditionalRender
                  condition={!!isSupportedSpeechToSpeech && flags[FeatureFlag.speechToSpeech]}
                >
                  <Tooltip title={intl.formatMessage(messages.transcriptVoiceTooltip)}>
                    <Button
                      onClick={() =>
                        onChangeTranscriptUrlVoiceSelection(!isTranscriptUrlVoiceSelected)
                      }
                      size="sm"
                      isIconOnly
                      variant="light"
                      radius="full"
                      color={isTranscriptUrlVoiceSelected ? "primary" : "default"}
                      startContent={<H1_Icon color={theme.gray11} icon="far fa-microphone" />}
                    />
                  </Tooltip>
                </ConditionalRender>
                <Button
                  radius="full"
                  isIconOnly
                  variant="light"
                  size="sm"
                  onClick={onUploadAudio}
                  startContent={<H1_Icon color={theme.gray11} icon="far fa-arrow-rotate-right" />}
                />
                <Button
                  radius="full"
                  isIconOnly
                  variant="light"
                  size="sm"
                  startContent={<H1_Icon color={theme.gray11} icon="far fa-trash" />}
                  onClick={onCancelAudioTranscript}
                />
              </H1_FlexRow>
            </H1_FlexRow>
          </AbsoluteFlexColumn>
        </H1_FlexColumn>
      </ConditionalRender>
      <ConditionalRender condition={!transcriptUrl}>
        <H1_FlexColumn>
          <TypeTextAnimationParagraph
            animatedTextPaddingRight={animatedTextPaddingRight}
            active={animatedText}
            isLoading={isAiLoading}
            text={scene?.attributes.text?.transcript?.text as string}
            onFinishAnimation={() => setAnimatedText(false)}
            lineHeight="22px"
          />
          <StyledLexicalEditor
            className="text-editor-box"
            useWordCounter
            withPause
            withPronunciation
            nodes={[PauseNode, PronunciationNode, VariableNode]}
            onPaste={onPaste}
            onApplySplitApprove={sendSegmentToScenes}
            maxLength={maxTranscriptLength}
            scenesLeft={scenesLeft}
            isSceneSelected={isSceneSelected}
            isReadOnly={isSceneReadOnly}
            placeholder={intl.formatMessage(messages.transcriptPlaceholder)}
            ButtonPause
            onBlur={updateText}
            isRichText
            name="sceneEditor"
            onRemoveInvalidChars={onRemoveInvalidChars}
            currentValue={currentTextAsSML}
            outsideEditorUpdate={outsideChangeSmlContent}
            pauseSupported={!!currentVoice?.pause_supported}
            onChange={onChangeInnerText}
            dataAutoIdAddon={idx.toString()}
            $visible={!animatedText}
            sceneId={sceneId}
            onUseVoice={onUseVoice}
            currentInnerText={currentInnerText}
            hideToolbar={hideToolbar}
          />
        </H1_FlexColumn>
      </ConditionalRender>
    </>
  );
};

export default React.memo(SceneMixedTagged);
