import React, { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import { videosActions } from "app/store/slices/videos.slice";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { validateCreateVideo } from "app/components/editor/validations";
import { scenesGlobalSelectors } from "app/store/adapters/adapters";
import { Template } from "app/types/template";
import useErrors from "app/hooks/useErrors";
import useModal, { ModalName } from "app/hooks/useModal";
import styled, { useTheme } from "styled-components";
import UiActions from "app/store/actions/ui.actions";
import { getLimits } from "app/store/selectorsV2/workspaces.selectors";
import useUpgradeModal from "app/hooks/useUpgradeModal";
import usePermissions from "app/hooks/usePermissions";
import { UserType } from "app/types";
import useIsFirstVideoCreated from "app/hooks/useIsFirstVideoCreated";
import { userActions } from "app/store/slices/user.slice";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";

export const messages = defineMessages({
  createLabel: {
    id: "editor.bake-video.button.create.label",
    defaultMessage: "Create Video"
  },
  videoCreationFailed: {
    id: "notification.validation.video-creation-failed",
    defaultMessage: "Video creation failed"
  },
  processingTooltip: {
    id: "editor.bake-video.tooltip.processing",
    defaultMessage: "Processing {progress}%"
  }
});

const StyledButton = styled(Button)`
  padding: 1px 13px;
  height: 40px;
  background-color: ${({ theme }) =>
    theme.mode === ThemeMode.Light ? "#2c285c" : theme.blue4}; /* Or approved */
  &:hover {
    background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#2c285c" : theme.blue4)};
  }
  &:active {
    background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#2c285c" : theme.blue4)};
  }
  svg {
    margin-right: 5px;
  }
`;

const CreateVideoButton = ({ source }: { source?: "quickPreview" | "editorTopBar" }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const showPaymentModal = useUpgradeModal();
  const { notifyError } = useErrors();
  const { openModal } = useModal();
  const { isOnboardingUser, isWriteWorkspaceAllow } = usePermissions();
  const isFirstVideoCreated = useIsFirstVideoCreated();
  const theme = useTheme();

  const draft = useAppSelector((state) => state.drafts.currentDraft);
  const videoCreateStatus = useAppSelector((state) => state.videos.videoCreateStatus);
  const scenes = useAppSelector(scenesGlobalSelectors.selectAll);
  const template: Template | undefined = useAppSelector((state) => state.templates.currentTemplate);
  const limits = useAppSelector(getLimits);
  const deleteSceneStatus = useAppSelector((state) => state.scenes.deleteSceneStatus);
  const scenesStatus = useAppSelector((state) => state.scenes.scenesStatus);
  const createSceneStatus = useAppSelector((state) => state.scenes.createSceneStatus);
  const preferences = useAppSelector((state) => state.user.preferences);

  const deleteSceneLoading = deleteSceneStatus === fetchingStatus.loading;
  const createVideoLoading = videoCreateStatus === fetchingStatus.loading;
  const scenesStatusLoading = scenesStatus === fetchingStatus.loading;
  const createSceneStatusLoading = createSceneStatus === fetchingStatus.loading;
  const disabled =
    !template ||
    !draft ||
    !isWriteWorkspaceAllow ||
    deleteSceneLoading ||
    scenesStatusLoading ||
    createSceneStatusLoading;
  const isUnableToCreatteVideos =
    (preferences.user_type_v2 === UserType.personal ||
      preferences.user_type_v2 === UserType.student) &&
    isOnboardingUser;
  const shouldUpgrade = template?.pro || isUnableToCreatteVideos;

  useEffect(() => {
    if (videoCreateStatus === fetchingStatus.succeeded) {
      dispatch(videosActions.updateVideoCreateStatusToIdle());
    } else if (videoCreateStatus === fetchingStatus.failed) {
      dispatch(videosActions.updateVideoCreateStatusToIdle());
    }
  }, [videoCreateStatus]);

  const onCreateVideo = () => {
    const { errors, sceneIdsErrors } = validateCreateVideo(
      draft,
      scenes,
      template as Template,
      intl,
      limits
    );

    if (Object.keys(sceneIdsErrors).length) {
      dispatch(UiActions.setValidationScroll(true));
      dispatch(UiActions.setSceneErrors(sceneIdsErrors));
    }
    if (Object.keys(errors).length) {
      notifyError(errors);
      dispatch(googleEvents.createVideo({ draftId: draft.id as string, success: false }));
    } else if (shouldUpgrade) {
      const upgradeRequired =
        showPaymentModal({
          source: "upgrade_create_video_button",
          requestedPlans: template?.plans ?? []
        }) || isUnableToCreatteVideos;

      if (!upgradeRequired) {
        if (!isFirstVideoCreated) {
          dispatch(userActions.updateUserPreferencesRequest({ is_first_video_created: true }));
        }
        openModal(ModalName.createVideoModalV2, { isCreateNewVideo: true, source });
      } else {
        openModal(ModalName.paymentModalV2, { source });
      }
    } else {
      if (!isFirstVideoCreated) {
        dispatch(userActions.updateUserPreferencesRequest({ is_first_video_created: true }));
      }
      openModal(ModalName.createVideoModalV2, { isCreateNewVideo: true, source });
    }
  };

  return (
    <>
      <StyledButton
        color="primary"
        data-auto-id="editor-create-video-button"
        isLoading={createVideoLoading}
        onClick={onCreateVideo}
        startContent={
          shouldUpgrade ? (
            <H1_Icon color={theme.orange4} icon="fas fa-crown" />
          ) : (
            <i className="fas fa-rocket" />
          )
        }
        isDisabled={disabled}
      >
        {intl.formatMessage(messages.createLabel)}
      </StyledButton>
    </>
  );
};

export default CreateVideoButton;
