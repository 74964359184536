import React, { useEffect, useMemo, useState } from "react";
import useNotifications from "app/hooks/useNotifications";
import * as filestackClient from "app/services/filestackClient";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import useErrors from "app/hooks/useErrors";
import { useIntl } from "react-intl";
import {
  StatusMessages,
  useReactMediaRecorder
} from "app/components/screenRecording/MediaRecorder";
import { generateThumbnail } from "app/utils/helpers";
import { InternalMedia, MediaSources, MediaType, VideoFit } from "app/types/media";
import ScreenRecordingSession from "app/components/screenRecording/ScreenRecordingSession";
import useModal, { ModalName } from "app/hooks/useModal";
import { screenRecordingMessages } from "app/components/screenRecording/messages";
import useTranscriptPlay from "app/hooks/useTranscriptPlay";
import { ListenStatus, voicesActions } from "app/store/slices/voices.slice";
import ConditionalRender from "app/components/common/ConditionalRender";
import { Draft, FilestackPolicy, MediaAssetProperties, TranscriptAudioPlayType } from "app/types";
import InitialSession from "app/components/screenRecording/InitialSession";
import UploadProgressModal from "app/components/screenRecording/UploadProgressModal";
import buildGeneralError from "app/hoc/ErrorNotifier/buildGeneralError";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import * as Sentry from "@sentry/react";
import usePreloadAudio from "app/components/screenRecording/usePreloadAudio";
import { COUNTER_URLS } from "app/components/screenRecording/assets";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import VideoSettingsModal from "app/components/editor/sideDrawers/SceneDrawer/VideoSettingsModal";
import { StyledModal } from "app/components/common/StyledModal";

const MIME_TYPE = "video/mp4";
const INIT_COUNTER_SEC = 6;

const ScreenRecording = () => {
  const [uploadedMediaUrl, setUploadedMediaUrl] = useState<string | undefined>();
  const [counter, setCounter] = useState<number>(INIT_COUNTER_SEC);
  const [sessionRunning, setSessionRunning] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [recordExecuted, setRecordExecuted] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [uploadSessionLoading, setUploadSessionLoading] = useState<boolean>(false);
  const [startCounter, setStartCounter] = useState<boolean>(false);
  const [showInitialScreen, setShowInitialScreen] = useState<boolean>(true);
  const { selectedSceneIndex, scene } = useSelectedScene();
  const intl = useIntl();
  const { closeModal, editorModalOpen } = useModal();
  const { notifyError } = useErrors();
  const { notifyMessages } = useNotifications();
  const dispatch = useAppDispatch();
  usePreloadAudio(Object.values(COUNTER_URLS));
  const {
    error,
    recordTimer,
    status: mediaRecorderStatus,
    startShareScreen,
    startRecording: startRecord,
    stopRecording: stopRecord,
    pauseRecording,
    resumeRecording,
    mediaBlobUrl,
    clearBlobUrl
  } = useReactMediaRecorder({ screen: true, audio: false, video: true });
  const { sceneId, assetKey, attributeType } = editorModalOpen?.context || {};

  const { onTogglePlayPause, isCurrentSceneLoaded, isCurrentDraftLoaded, rewind } =
    useTranscriptPlay({
      type:
        editorModalOpen?.context?.type === TranscriptAudioPlayType.draft
          ? TranscriptAudioPlayType.draft
          : TranscriptAudioPlayType.scene,
      sceneId: sceneId as string,
      autoPlay: false
    });
  const audioLoaded = isCurrentSceneLoaded || isCurrentDraftLoaded;
  const filestackPolicy = useAppSelector((state) => state.media.filestackReadPolicy);
  const footerAudioPlayer = useAppSelector(({ voices }) => voices.footerAudioPlayer);
  const currentDraft: Draft = useAppSelector((state) => state.drafts.currentDraft);

  const loading = footerAudioPlayer.status === ListenStatus.loading;
  const audioError = footerAudioPlayer.status === ListenStatus.failed;
  const isPaused = mediaRecorderStatus === StatusMessages.paused;
  const draftId = currentDraft?.id as string;
  const currentAsset = scene?.layout?.assets[attributeType as "visual" | "media"];

  const videoSettingsAssetProperties = useMemo(() => {
    let res = {
      ...(scene?.attributes?.[attributeType as "visual" | "media"]?.[
        assetKey
      ] as MediaAssetProperties),
      url: uploadedMediaUrl as string
    };

    if (attributeType === "visual") {
      res = {
        ...res,
        preset_override: {
          media_url: uploadedMediaUrl,
          type: "media"
        }
      };
    }

    return res;
  }, [
    uploadedMediaUrl,
    assetKey,
    attributeType,
    scene?.attributes?.[attributeType as "visual" | "media"]?.[assetKey]
  ]);

  useEffect(() => {
    if (audioError) {
      Sentry.captureMessage(`screen recording failed with audio error`);
    }
  }, [audioError]);

  useEffect(() => {
    const getUploadPolicy = async () => {
      const { payload: uploadPolicy } = await dispatch(
        mediaActions.getMediaPolicyRequest("upload")
      );

      filestackClient.init(uploadPolicy as FilestackPolicy, "upload");
      await uploadToFileStack();
    };
    if (mediaBlobUrl) {
      setUploadSessionLoading(true);
      getUploadPolicy();
    }
  }, [mediaBlobUrl]);

  useEffect(() => {
    if (!audioLoaded) {
      onTogglePlayPause();
    }
    return () => {
      dispatch(voicesActions.cleanFooterAudio());
    };
  }, []);

  useEffect(() => {
    if (error) {
      console.error("error when screen recording", error);
      if (error !== "permission_denied") {
        notifyMessages([{ message: `Error: ${error}` }]);
      }
      onCloseSession();
    }
  }, [error]);
  useEffect(() => {
    if (mediaRecorderStatus === StatusMessages.shareScreenActivated) {
      startSession();
    } else if (mediaRecorderStatus === StatusMessages.shareScreenDeactivatedBeforeRecord) {
      onCloseSession();
    }
  }, [mediaRecorderStatus]);

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval> | null = null;
    if (startCounter) {
      const handler = () => {
        if (counter <= 0) {
          executeRecord();
          if (intervalId) {
            clearInterval(intervalId);
          }
        } else {
          setCounter(counter - 1);
          if (intervalId) {
            clearInterval(intervalId);
          }
        }
      };
      intervalId = setInterval(handler, 1000);
    } else if (intervalId) {
      clearInterval(intervalId);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [startCounter, counter]);

  const onStartShareScreen = () => {
    setFileName(`Screen_Captured_${new Date().toISOString()}`);
    setRecordExecuted(false);
    startShareScreen();
  };
  const executeRecord = () => {
    startRecord();
    dispatch(voicesActions.updateFooterAudioPlayer({ status: ListenStatus.play }));
    setRecordExecuted(true);
  };

  const startSession = () => {
    // here we start the session of recording after getting permission
    // first step -> trigger player - once status is ready for play - > need to call setSesstion running true
    setCounter(INIT_COUNTER_SEC);
    if (audioLoaded) {
      setStartCounter(true);
    }
    setSessionRunning(true);
  };

  const uploadToFileStack = async () => {
    try {
      setUploadSessionLoading(true);
      const blob = await (await fetch(mediaBlobUrl as string)).blob();
      const file = new File([blob.slice(0, blob.size, MIME_TYPE)], fileName, {
        type: MIME_TYPE
      });
      const uploadResult = await filestackClient.getClient("upload")?.upload(file, {
        onProgress: ({ totalPercent }) => {
          setProgress(totalPercent);
        }
      });

      const localThumbnail = await generateThumbnail(file, uploadResult.url);
      await filestackClient.createVideoThumbnail(filestackPolicy, uploadResult.handle);

      const media: Omit<InternalMedia, "id"> = {
        url: uploadResult.url,
        handle: uploadResult.handle,
        status: "done",
        fileName: uploadResult.filename,
        uploaded: new Date().getTime(),
        mediaType: MediaType.video,
        size: file.size,
        mimetype: MIME_TYPE,
        thumbnail: localThumbnail.thumbnail,
        thumbnailType: localThumbnail.thumbnailType,
        source: MediaSources.upload
      };

      dispatch(mediaActions.createMediaRequest(media));
      setUploadSessionLoading(false);
      setUploadedMediaUrl(uploadResult.url);
    } catch (err) {
      console.error("upload screen capture error", err);
      notifyError({
        general: buildGeneralError(
          intl.formatMessage(screenRecordingMessages.failedUploadError),
          intl
        )
      });
    }
  };

  const onStop = () => {
    stopRecord();
    rewind();
    if (!recordExecuted) {
      //  stop has called before start record
      onCloseSession();
    }
  };

  const onCloseSession = async () => {
    dispatch(analyticsEvents.screenRecord({ status: "cancel" }));
    setSessionRunning(false);
    setStartCounter(false);
    clearBlobUrl();
    setProgress(0);
    closeModal();
  };

  const togglePause = () => {
    if (isPaused) {
      resumeRecording();
    } else {
      pauseRecording();
    }
    onTogglePlayPause();
  };

  const onStartRecording = () => {
    dispatch(analyticsEvents.screenRecord({ status: "startRecord" }));
    setShowInitialScreen(false);
    onStartShareScreen();
  };
  const onCancelStartSession = () => {
    dispatch(analyticsEvents.screenRecord({ status: "cancelRecord" }));

    setSessionRunning(false);
    setStartCounter(false);
    setShowInitialScreen(true);
    if (mediaRecorderStatus === StatusMessages.shareScreenActivated) {
      stopRecord();
    }
    clearBlobUrl();
    rewind();
  };

  const onRetryRecord = () => {
    dispatch(analyticsEvents.screenRecord({ status: "recordAgain" }));

    setSessionRunning(false);
    setStartCounter(true);
    clearBlobUrl();
    rewind();
    onStartRecording();
  };

  return (
    <StyledModal
      transitionName=""
      maskTransitionName=""
      closable={false}
      destroyOnClose
      footer={null}
      open
      centered
      className="no-blurry-background"
    >
      <ConditionalRender condition={showInitialScreen}>
        <InitialSession
          loading={loading}
          onClose={onCloseSession}
          onStartRecording={onStartRecording}
          audioError={audioError}
        />
      </ConditionalRender>
      <ConditionalRender condition={uploadSessionLoading}>
        <UploadProgressModal progress={progress} />
      </ConditionalRender>
      <ConditionalRender condition={!uploadSessionLoading && sessionRunning}>
        <ScreenRecordingSession
          onCancel={onCancelStartSession}
          loading={loading}
          status={mediaRecorderStatus}
          counter={counter}
          mediaBlobUrl={mediaBlobUrl}
          recordTimer={recordTimer}
          recordExecuted={recordExecuted}
          togglePause={togglePause}
          paused={isPaused}
          onStop={onStop}
        />
      </ConditionalRender>
      <VideoSettingsModal
        source="ScreenRecorder"
        sceneIdx={selectedSceneIndex}
        initialVideoFit={VideoFit.Pad}
        visible={!!(sessionRunning && mediaBlobUrl && !uploadSessionLoading)}
        onClose={onCloseSession}
        url={mediaBlobUrl as string}
        ratioX={currentAsset ? (currentAsset[0].restrictions?.ratio_w as number) : 16}
        ratioH={currentAsset ? (currentAsset[0].restrictions?.ratio_h as number) : 9}
        assetProperties={videoSettingsAssetProperties}
        assetKey={assetKey}
        attributeType={attributeType}
        draftId={draftId}
        sceneId={sceneId}
        onRetryRecord={onRetryRecord}
        audioUrl={footerAudioPlayer.url}
      />
    </StyledModal>
  );
};

const ConditionalScreenRecording = () => {
  const { editorModalOpen } = useModal();

  const visible = editorModalOpen?.open === ModalName.screenRecording;

  return (
    <ConditionalRender condition={visible}>
      <ScreenRecording />
    </ConditionalRender>
  );
};

export default ConditionalScreenRecording;
