import React, { Key, useContext, useEffect, useMemo, useState } from "react";

import DraftsView from "app/components/projects-managment/DraftsView/DraftsView";

import "app/pages/HomePage/HomePage.scss";
import TemplatesCollection from "app/pages/HomePage/TemplateCollection/TemplatesCollection";
import { draftsActions } from "app/store/slices/drafts.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import { recipesActions } from "app/store/slices/recipes.slice";
import { videosActions } from "app/store/slices/videos.slice";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  DraftViewMode,
  FeatureFlag,
  HeaderTab,
  UseCaseSegmentType,
  WorkspaceType
} from "app/types";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import HomePageInvitations from "app/pages/HomePage/HomePageInvitations";
import styled, { keyframes, useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import HomePageSkeletonLoading from "app/pages/HomePage/HomePageSkeletonLoading";
import SocialMediaCollection from "app/pages/HomePage/SocialMediaCollection";
import useDropdownHandler from "app/hooks/useDropdownHandler";
import { RootState } from "app/store/store";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { HeaderContext } from "app/hoc/HeaderContext";
import HomePageWorkspace from "app/pages/HomePage/HomePageWorkspace";
import { videosGlobalSelectors } from "app/store/adapters/adapters";
import { ThemeMode } from "app/utils/theme";
import HomePageTopBanners from "app/pages/HomePage/HomePageTopBanners";
import { type Selection } from "@nextui-org/react";
import { useIntl } from "react-intl";
import { homePageMessages } from "app/pages/HomePage/messages";
import { userActions } from "app/store/slices/user.slice";
import NextSelect from "app/components/common/NextUI/Select/NextSelect";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import HomePageVideoSelections from "app/pages/HomePage/HomePageVideoSelections";
import MediaEntertainmentStrip from "app/pages/HomePage/MediaEntertainmentStrip";

const tilt = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(1deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const StyledSelect = styled(NextSelect)`
  margin-right: 20px;
  &&& button {
    animation: ${tilt} 0.5s ease-in-out 0s 2; /* Animation lasts 0.5 seconds, repeats twice */
    transition: transform 0.3s ease-in-out;
    background: linear-gradient(
      135deg,
      ${({ theme }) => theme.gray3},
      ${({ theme }) => theme.gray5}
    );
    background: linear-gradient(90deg, rgba(171, 171, 255, 0.5) 0%, rgba(103, 103, 153, 0.5) 100%);
    background: ${({ theme }) =>
      theme.mode === ThemeMode.Light
        ? `linear-gradient(90deg, rgba(0, 0, 0, 0.80) 0%, rgba(171, 171, 255, 0.80) 100%)`
        : `linear-gradient(90deg, rgba(171, 171, 255, 0.5) 0%, rgba(103, 103, 153, 0.5) 100%)`};
    border: 1px solid #ababff;

    &:hover {
      background-color: ${({ theme }) =>
        theme.mode === ThemeMode.Light ? theme.gray4 : theme.gray4};
      transform: scale(1.03);
    }

    label {
      font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
      font-size: 16px;
      color: #d4d4d8;
    }
    span[data-slot="value"] {
      font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
      font-size: 14px;
      color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#FAFAFA" : theme.gray6)};
    }
  }
`;

const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray12)};
`;

const HomePage = () => {
  const [showTemplatesCollection, setShowTemplatesCollection] = useState<boolean>(true);
  const [isComponentLoaded, setIsComponentLoaded] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const flags = useFlags();
  const { activeHeaderTab, setActiveHeaderTab } = useContext(HeaderContext);

  useDropdownHandler({
    goFoldersOnDelete: false,
    goDraftOnDuplicate: false,
    activeApiExampleHandler: true
  });

  const draftsStatus = useAppSelector((state) => state.drafts.draftsStatus);

  const isDraftsLoading = draftsStatus === fetchingStatus.loading && !isComponentLoaded;
  const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));
  const preferences = useAppSelector((state) => state.user.preferences);
  const workflowCategories = useAppSelector((state: RootState) => state.drafts.workflowCategories);
  const allVideos = useAppSelector(videosGlobalSelectors.selectAll);
  const isIconicWorkspace =
    currentWorkspace?.metadata?.type === WorkspaceType.Iconic && flags[FeatureFlag.iconic];
  const usageSegment = preferences.usage_segment;
  const isUseCaseMediaEntertainment = usageSegment === UseCaseSegmentType.MediaEntertainment;

  useEffect(() => {
    if (!workflowCategories) {
      dispatch(draftsActions.getWorkflowCategoriesRequest());
    }
  }, [workflowCategories]);

  useEffect(() => {
    dispatch(recipesActions.getRecipesRequest());
    setActiveHeaderTab(HeaderTab.home);
    return () => {
      dispatch(videosActions.cleanVideos());
      dispatch(draftsActions.resetDraftStore());
    };
  }, [currentWorkspace?.id]);

  useEffect(() => {
    if (currentWorkspace?.id) {
      dispatch(
        draftsActions.getDraftsRequest({
          limit: 10,
          page: 1,
          viewMode: preferences.view_mode_me ? DraftViewMode.me : undefined
        })
      );
    }
  }, [currentWorkspace?.id, preferences.view_mode_me]);
  useEffect(() => {
    if (draftsStatus === fetchingStatus.succeeded) {
      setIsComponentLoaded(true);
      dispatch(draftsActions.updateDraftsStatusToIdle());
      if (allVideos.length === 0) {
        dispatch(
          videosActions.getVideosFeedRequest({
            limit: 20,
            skip: 0
          })
        );
      }
    } else if (draftsStatus === fetchingStatus.failed) {
      setIsComponentLoaded(true);
      dispatch(draftsActions.updateDraftsStatusToIdle());
    }
  }, [draftsStatus]);

  const useCaseSelectionsItems = useMemo(() => {
    const items = [
      {
        key: UseCaseSegmentType.Training,
        label: formatMessage(homePageMessages.lnd),
        startContent: <H1_Icon color={theme.gray8} size="12px" icon="fas fa-chalkboard-user" />
      },
      {
        key: UseCaseSegmentType.Sales,
        label: formatMessage(homePageMessages.sales),
        startContent: <H1_Icon color={theme.gray8} size="12px" icon="fas fa-comments-dollar" />
      },
      {
        key: UseCaseSegmentType.Marketing,
        label: formatMessage(homePageMessages.marketing),
        startContent: <H1_Icon color={theme.gray8} size="12px" icon="fas fa-megaphone" />
      },
      {
        key: UseCaseSegmentType.News,
        label: formatMessage(homePageMessages.developers),
        startContent: <H1_Icon color={theme.gray8} size="12px" icon="fas fa-code" />
      }
    ];
    if (flags[FeatureFlag.mediaAndEntertainment]) {
      items.push({
        key: UseCaseSegmentType.MediaEntertainment,
        label: formatMessage(homePageMessages.mediaEntertainment),
        startContent: (
          <H1_Icon color={theme.gray8} size="12px" icon="fa-sharp fa-solid fa-camera-movie" />
        )
      });
    }
    return items;
  }, [theme.mode, flags[FeatureFlag.mediaAndEntertainment]]);

  const onSelectionChange = (value: Selection) => {
    const parsedValue = Array.from(value)[0] as string;
    dispatch(userActions.updateUserPreferencesRequest({ usage_segment: parsedValue }));
  };

  if (activeHeaderTab === HeaderTab.workspace) {
    return <HomePageWorkspace />;
  }

  return (
    <BackgroundFlexColumn flex="1 1 auto" overflow="auto" position="relative">
      <>
        <HomePageTopBanners />
        <H1_FlexRow gap="30px" padding="0 50px">
          <ConditionalRender condition={!isIconicWorkspace}>
            <StyledSelect
              selectorIcon={<H1_Icon color="white" icon="far fa-chevron-down" />}
              size="lg"
              label={formatMessage(homePageMessages.selectUseCaseTitle)}
              selectedKeys={[usageSegment as Key]}
              onSelectionChange={onSelectionChange}
              items={useCaseSelectionsItems}
              maxWidth="263px"
              classNames={{
                listbox: "text-gray-8",
                popoverContent: "bg-gray-1/85 backdrop-blur-[4px]"
              }}
            />
          </ConditionalRender>
          <HomePageVideoSelections
            onShowTemplatesCollection={setShowTemplatesCollection}
            showTemplatesCollection={showTemplatesCollection}
          />
        </H1_FlexRow>
        <ConditionalRender condition={flags[FeatureFlag.socialMediaCollection] && false}>
          <SocialMediaCollection />
        </ConditionalRender>
        <ConditionalRender condition={showTemplatesCollection && !isUseCaseMediaEntertainment}>
          <TemplatesCollection />
        </ConditionalRender>
        <ConditionalRender condition={isUseCaseMediaEntertainment}>
          <MediaEntertainmentStrip />
        </ConditionalRender>
        <ConditionalRender condition={!showTemplatesCollection}>
          <H1_FlexRow flex="0 0 30px" />
        </ConditionalRender>
        <ConditionalRender condition={isDraftsLoading && !isComponentLoaded}>
          <HomePageSkeletonLoading />
        </ConditionalRender>
        <ConditionalRender condition={!isDraftsLoading || isComponentLoaded}>
          <DraftsView />
        </ConditionalRender>
        <ConditionalRender condition={!preferences.show_on_boarding_survey}>
          <HomePageInvitations />
        </ConditionalRender>
      </>
    </BackgroundFlexColumn>
  );
};

export default HomePage;
