import React, { useEffect, useMemo } from "react";
import styled, { useTheme } from "styled-components";
import { Progress, Skeleton } from "antd";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import { videosActions } from "app/store/slices/videos.slice";
import useModal, { ModalName } from "app/hooks/useModal";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { Location, PlanEnum, VideoStatusEnum } from "app/types";
import { useNavigate } from "react-router-dom";
import useSmallScreen from "app/hooks/useSmallScreen";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";

import {
  H1_TextHeadline,
  H1_TextSmall,
  H1_TextSubtitle
} from "app/components/_Infrastructure/Typography";
import ConditionalRender from "app/components/common/ConditionalRender";
import * as videosSelector from "app/store/selectorsV2/videos.selectors";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { createVideoModalMessages } from "app/pages/editor/CreateVideoModalV2/messages";
import { useIntl } from "react-intl";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import useUpgradeModal from "app/hooks/useUpgradeModal";
import { Button } from "@nextui-org/react";
import { StyledModal } from "app/components/common/StyledModal";

const Modal = styled(StyledModal)<{ $cancelAnimation: boolean }>`
  animation-duration: ${({ $cancelAnimation }) => $cancelAnimation && "0s !important"};
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: 12px;
  }
`;

const LineButton = styled(Button)`
  color: ${({ theme }) => theme.gray6};
  text-decoration: underline;
  i {
    color: ${({ theme }) => theme.gray6};
  }
  span {
    color: ${({ theme }) => theme.gray6};
    text-decoration: underline;
  }
  &:hover {
    color: ${({ theme }) => theme.gray8};
    i {
      color: ${({ theme }) => theme.gray8};
    }
    span {
      color: ${({ theme }) => theme.gray8};
    }
  }
`;

const CloseIconContainer = styled.div`
  margin-right: 10px;
  margin-top: 10px;
  i {
    font-size: 24px;
    color: ${(props) => props.theme.gray1};
    &:hover {
      color: ${(props) => props.theme.blue4};
    }
  }
`;

const TopFlexColumn = styled(H1_FlexColumn)<{ $background: string; $backgroundColor: string }>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  background-image: ${({ $background }) => `url(${$background})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
  background-position-x: center;
`;

const WidthTextLargeHeadline = styled(H1_TextHeadline)`
  max-width: 364px;
`;

const WidthTextSmall = styled(H1_TextSmall)`
  max-width: 364px;
  display: block;
`;

const PointerTextSmall = styled(H1_TextSmall)`
  cursor: pointer;
`;

const CreateVideoModalV2 = () => {
  const { closeModal, openModal, editorModalOpen } = useModal();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isSmallScreen } = useSmallScreen();
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { currentPlan } = useCurrentPlan();
  const showPaymentModal = useUpgradeModal();
  const currentDraft = useAppSelector((state) => state.drafts.currentDraft);
  // @ts-ignore handels userUI store typing
  const view = useAppSelector((state) => state.userUi.view);
  const lastVideo = useAppSelector(videosSelector.getLastVersionVideo);

  const videoCreateStatus = useAppSelector((state) => state.videos.videoCreateStatus);
  const videoCreateError = useAppSelector((state) => state.videos.videoCreateError);
  const cancelVideoCreateStatus = useAppSelector((state) => state.videos.cancelVideoCreateStatus);
  const isVideoLoading = videoCreateStatus === fetchingStatus.loading;
  const visible = editorModalOpen?.open === ModalName.createVideoModalV2;
  const isShowUpgrade = currentPlan === PlanEnum.onBoarding || currentPlan === PlanEnum.personal;
  const isVideoCanceled = lastVideo?.status === VideoStatusEnum.Canceled;

  useEffect(() => {
    if (cancelVideoCreateStatus === fetchingStatus.succeeded && lastVideo?.id) {
      dispatch(videosActions.updateCancelVideoStateToIdle());
    }
    if (cancelVideoCreateStatus === fetchingStatus.failed) {
      dispatch(videosActions.updateCancelVideoStateToIdle());
    }
  }, [cancelVideoCreateStatus]);

  const onClickUpgrade = (upgradeText: string) => {
    showPaymentModal({
      source: "upgrade-create-video-tip",
      backModal: ModalName.createVideoModalV2,
      requestedPlans: [PlanEnum.business],
      upgradeText
    });
  };

  const onClickCancelRendering = () => {
    dispatch(
      googleEvents.cancelVideoRendering({
        draftId: currentDraft.id as string,
        req_id: lastVideo.req_id,
        videoId: lastVideo.id
      })
    );
    dispatch(videosActions.cancelVideoRequest({ videoId: lastVideo.id }));
    closeModal();
  };

  const upgradeNowContent = (upgradeText: string) => (
    <ConditionalRender condition={isShowUpgrade}>
      <PointerTextSmall
        inline
        underline
        color={theme.gray1}
        whiteSpace="initial"
        onClick={() => onClickUpgrade(upgradeText)}
      >
        {formatMessage(createVideoModalMessages.upgradeNow)}
      </PointerTextSmall>
    </ConditionalRender>
  );

  const tips = useMemo(
    () => [
      {
        title: formatMessage(createVideoModalMessages.tip),
        content: formatMessage(createVideoModalMessages.translateContent),
        // @ts-ignore - Handles formatting issue with html tags params
        subContent: formatMessage(createVideoModalMessages.translateSubContent, {
          span: () =>
            upgradeNowContent(formatMessage(createVideoModalMessages.translateUpgradeText))
        }),
        backgroundImageUrl: `https://df6g5g0b3bt51.cloudfront.net/reals-static-files/translate.webp`,
        backgroundColor: "#f7795b"
      },
      {
        title: formatMessage(createVideoModalMessages.tip),
        content: formatMessage(createVideoModalMessages.subtitlesContent),
        // @ts-ignore - Handles formatting issue with html tags params
        subContent: formatMessage(createVideoModalMessages.subtitlesSubContent, {
          span: () =>
            upgradeNowContent(formatMessage(createVideoModalMessages.subtitlesUpgradeText))
        }),
        backgroundImageUrl: `https://df6g5g0b3bt51.cloudfront.net/reals-static-files/subtitles.webp`,
        backgroundColor: "#f7795b"
      },
      {
        title: formatMessage(createVideoModalMessages.tip),
        content: formatMessage(createVideoModalMessages.cloneVoiceContent),
        // @ts-ignore - Handles formatting issue with html tags params
        subContent: formatMessage(createVideoModalMessages.cloneVoiceSubContent, {
          span: () =>
            upgradeNowContent(formatMessage(createVideoModalMessages.cloneVoiceUpgradeText))
        }),
        backgroundImageUrl: `https://df6g5g0b3bt51.cloudfront.net/reals-static-files/clonevoice.webp`,
        backgroundColor: "#5980ab"
      },
      {
        title: formatMessage(createVideoModalMessages.tip),
        content: formatMessage(createVideoModalMessages.scenesContent),
        // @ts-ignore - Handles formatting issue with html tags params
        subContent: formatMessage(createVideoModalMessages.scenesSubContent, {
          span: () => upgradeNowContent(formatMessage(createVideoModalMessages.scenesUpgradeText))
        }),
        backgroundImageUrl: `https://df6g5g0b3bt51.cloudfront.net/reals-static-files/scenes.webp`,
        backgroundColor: "#2e4a43"
      },
      {
        title: formatMessage(createVideoModalMessages.tip),
        content: formatMessage(createVideoModalMessages.brandKitContent),
        // @ts-ignore - Handles formatting issue with html tags params
        subContent: formatMessage(createVideoModalMessages.brandKitSubContent, {
          span: () => upgradeNowContent(formatMessage(createVideoModalMessages.brandKitUpgradeText))
        }),
        backgroundImageUrl: `https://df6g5g0b3bt51.cloudfront.net/reals-static-files/brandkit.webp`,
        backgroundColor: "#f97d75"
      }
    ],
    [isShowUpgrade, visible]
  );

  const randomTip = useMemo(() => Math.floor(Math.random() * tips.length), [visible]);
  const onClose = () => {
    if (currentDraft) {
      dispatch(
        googleEvents.createModalClose({
          draftId: currentDraft.id as string
        })
      );
    }
    closeModal();
  };

  useEffect(() => {
    if (!visible) {
      return;
    }
    if (videoCreateStatus === fetchingStatus.succeeded) {
      dispatch(videosActions.updateVideoCreateStatusToIdle());
    } else if (videoCreateStatus === fetchingStatus.failed) {
      if (videoCreateError && videoCreateError?.status === 402) {
        openModal(ModalName.noEnoughCredits);
      } else if (videoCreateError && videoCreateError?.status === 400) {
        closeModal();
      } else if (
        videoCreateError &&
        videoCreateError?.status === 422 &&
        ["Didn't pass voice moderation test", "Didn't pass transcript moderation test"].includes(
          videoCreateError?.message || ""
        )
      ) {
        closeModal();
      } else openModal(ModalName.videoFailed);
      dispatch(videosActions.updateVideoCreateStatusToIdle());
    }
  }, [videoCreateStatus, visible]);

  useEffect(() => {
    const context = editorModalOpen?.context;
    if (visible && context && context.isCreateNewVideo && currentDraft) {
      // todo the character id and voice id is not relevant for cinematic on analytics.
      const characterId = currentDraft.attributes?.character?.character?.character_id;
      const voiceId = currentDraft.attributes?.voice?.voice?.voice_id;
      const currentDraftId = currentDraft.id as string;
      dispatch(
        videosActions.createVideoRequest({
          draftId: currentDraftId,
          skip402ErrorHandling: true
        })
      );
      dispatch(
        googleEvents.createVideo({
          draftId: currentDraftId,
          success: true,
          characterId: characterId as string,
          voiceId: voiceId as string,
          view,
          source: context.source
        })
      );
      if (isSmallScreen) {
        navigate(`${Location.MobileCreate}/${currentDraftId}`);
      }
    }
  }, [editorModalOpen?.context?.isCreateNewVideo, visible]);

  return (
    <Modal
      $cancelAnimation={isVideoCanceled}
      width="803px"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "374px" }}
      onCancel={onClose}
      closeIcon={
        <CloseIconContainer onClick={onClose}>
          <i className="far fa-xmark" />
        </CloseIconContainer>
      }
    >
      {/* Top */}
      <TopFlexColumn
        position="relative"
        padding="46px 44px"
        gap="7px"
        $background={tips[randomTip].backgroundImageUrl}
        $backgroundColor={tips[randomTip].backgroundColor}
        flex="1"
      >
        <H1_TextSubtitle color="white" margin="0 0 7px 0">
          {tips[randomTip].title}
        </H1_TextSubtitle>
        <WidthTextLargeHeadline color="white" whiteSpace="initial">
          {tips[randomTip].content}
        </WidthTextLargeHeadline>
        <WidthTextSmall color="white" whiteSpace="initial">
          {tips[randomTip].subContent}
        </WidthTextSmall>
      </TopFlexColumn>
      {/* Bottom */}
      <H1_FlexColumn padding="25px 40px" height="95px">
        <ConditionalRender condition={isVideoLoading}>
          <H1_FlexRow height="11px" align="flex-start">
            <Skeleton loading active paragraph={{ rows: 0 }} title={{ width: "100%" }} />
          </H1_FlexRow>
        </ConditionalRender>
        <ConditionalRender condition={!isVideoLoading}>
          <H1_FlexColumn>
            <H1_FlexRow width="100%">
              <Progress
                strokeLinecap="round"
                strokeColor={theme.blue4}
                trailColor={theme.gray3}
                percent={lastVideo?.progress || 0}
                strokeWidth={11}
                showInfo={false}
                status={visible ? "active" : undefined}
              />
            </H1_FlexRow>
            <H1_FlexRow justify="space-between">
              <H1_FlexRow align="center" gap="18px">
                <H1_TextSmall fontWeight={700} color={theme.gray9}>
                  {lastVideo?.progress || 0}%
                </H1_TextSmall>
                <H1_TextSmall color={theme.gray9}>
                  {formatMessage(createVideoModalMessages.yourVideoCreated)}
                </H1_TextSmall>
              </H1_FlexRow>
              <ConditionalRender condition={!!currentDraft && !!lastVideo}>
                <LineButton
                  isDisabled={!lastVideo}
                  variant="light"
                  onClick={onClickCancelRendering}
                  size="sm"
                >
                  {formatMessage(createVideoModalMessages.cancelRendering)}
                </LineButton>
              </ConditionalRender>
            </H1_FlexRow>
          </H1_FlexColumn>
        </ConditionalRender>
      </H1_FlexColumn>
    </Modal>
  );
};

export default CreateVideoModalV2;
