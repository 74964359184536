import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import ConditionalRender from "app/components/common/ConditionalRender";
import { Tooltip, Typography } from "antd";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { CharacterRecord, LayoutAsset, PatchOperation, Voice } from "app/types";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import styled, { useTheme } from "styled-components";
import { Button } from "@nextui-org/react";
import { useMemo } from "react";
import { sceneDrawerMessages } from "./messages";
import { useIntl } from "react-intl";
import * as characterSelectors from "app/store/selectorsV2/character.selectors";
import * as voiceSelectors from "app/store/selectorsV2/voices.selectors";
import { useAppDispatch, useAppSelector } from "app/hooks";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import { scenesActions } from "app/store/slices/scenes.slice";
import { replaceFlagSeparator } from "app/utils/helpers";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { voicesActions } from "app/store/slices/voices.slice";
import { ThemeMode } from "app/utils/theme";

const EyeButton = styled(Button)`
  i {
    cursor: pointer;
    font-size: 11px;
  }
`;
const CharacterContainerFlexRow = styled(H1_FlexRow)`
  border-radius: 4px;
`;
const Circle = styled(H1_FlexRow)<{ $isActive: boolean }>`
  transition: 0.3s background-color ease-in-out;
  background-color: ${({ theme, $isActive }) => ($isActive ? theme.blue4 : theme.gray8)}${({ $isActive }) => ($isActive ? "ff" : "33")};
  border-radius: 50%;
`;
const TalkingFlexRow = styled(H1_FlexRow)<{ $isActive: boolean }>`
  span {
    transition: 0.3s color ease-in-out;
  }
  &:hover {
    span {
      color: ${({ theme, $isActive }) => ($isActive ? theme.blue4 : theme.gray8)}ff;
    }
    ${Circle} {
      background-color: ${({ theme, $isActive }) => ($isActive ? theme.blue4 : theme.gray8)}ff;
    }
  }
`;
// const RightSwapButtonContainer = styled(H1_FlexRow)`
//   right: 0;
//   top: 40px;
//   position: absolute;
//   border-radius: 50%;
//   box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
//   border: 1px solid ${({ theme }) => theme.gray4};
//   background-color: ${({ theme }) => theme.gray1};
//   &:hover {
//     box-shadow: none;
//     border: 1px solid ${({ theme }) => theme.gray5};
//   }
//   &:active {
//     border: 1px solid ${({ theme }) => theme.gray6};
//   }
// `;
const EntityBorderFlexRow = styled(H1_FlexRow)<{ $visible?: boolean }>`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.gray3};
  opacity: ${({ $visible }) => ($visible === true || $visible === undefined ? 1 : 0.2)};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray3)};

  &:hover {
    background-color: ${({ theme }) => theme.gray2};
  }
`;
const FlagImg = styled.img`
  border-radius: 10px;
  width: 42px;
  height: 32px;
  object-fit: cover;
`;
const CharacterImg = styled.img`
  border-radius: 10px;
  width: 32px;
  height: 32px;
  object-fit: cover;
  object-position: top 7% left 0;
  background-color: rgba(211, 211, 211, 0.5);
  transform: scale(1.5);
`;

const NameText = styled(Typography.Text)`
  font-size: 12px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => props.theme.gray8};
  text-align: center;
`;

interface ContentSectionCharacterProps {
  draftId: string;
  assetKey: string;
  characterAsset: LayoutAsset;
  isMultipleCharacters: boolean;
  characterIndex: number;
}

const ContentSectionCharacter = ({
  draftId,
  assetKey,
  characterAsset,
  isMultipleCharacters,
  characterIndex
}: ContentSectionCharacterProps) => {
  const intl = useIntl();
  const voiceAssetKey = characterAsset?.restrictions?.voice_key || "voice";
  const shotType = characterAsset?.restrictions?.shot_type;
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { scene, sceneId } = useSelectedScene();
  const { openDrawer } = useDrawer();

  const selectedCharacter = useAppSelector((state) =>
    characterSelectors.getDrawerCharacterBySelectedScene(state, assetKey)
  );

  const selectedVoice: Voice | undefined = useAppSelector((state) =>
    voiceSelectors.getSelectedVoiceForDrawer(state, voiceAssetKey)
  );

  const charactersAssets = scene?.layout.assets.character || [];
  const characterPresetOverride = useMemo(
    () =>
      scene?.attributes?.character &&
      (scene?.attributes?.character?.[assetKey] as CharacterRecord)?.preset_override,
    [sceneId, scene?.attributes?.character]
  );

  const isLayoutCharacterPreset = !!characterAsset.preset;
  const currentCharacterVisible =
    characterAsset &&
    ((characterPresetOverride?.has_character === undefined &&
      (characterAsset.preset?.has_character ||
        characterAsset.preset?.has_character === undefined)) ||
      characterPresetOverride?.has_character === true);
  const isCharacterTalking =
    characterAsset &&
    isMultipleCharacters &&
    ((characterPresetOverride?.is_talking === undefined &&
      (characterAsset.preset?.is_talking || characterAsset.preset?.is_talking === undefined)) ||
      characterPresetOverride?.is_talking === true);

  const avatarEyeTooltip = useMemo(() => {
    if (!characterAsset) {
      return intl.formatMessage(sceneDrawerMessages.avatarEyeNotSupportedTooltip);
    }
    if (currentCharacterVisible) {
      return intl.formatMessage(sceneDrawerMessages.avatarEyeHideTooltip);
    } else {
      return intl.formatMessage(sceneDrawerMessages.avatarEyeShowTooltip);
    }
  }, [characterAsset, currentCharacterVisible]);

  const onClickAvatarEye = () => {
    const operations = [
      {
        op: "replace",
        path: `attributes.character.${assetKey}.preset_override.has_character`,
        value: !currentCharacterVisible
      }
    ] as PatchOperation[];
    dispatch(
      scenesActions.patchSceneRequest({
        draftId,
        sceneId,
        operations
      })
    );
  };
  const flagSvg = replaceFlagSeparator(selectedVoice?.local);

  const onClickAvatar = () => {
    if (currentCharacterVisible) {
      openDrawer(Drawer.Presenter, { assetKey: assetKey, voiceAssetKey: voiceAssetKey, shotType });
    }
  };

  const onClickVoice = () => {
    openDrawer(Drawer.Voices, { characterAssetKey: assetKey, assetKey: voiceAssetKey });
  };

  // const onClickSwapAvatars = () => {
  // todo
  // let operations: PatchOperation[] = [];
  // const firstCharacter = charactersAssets[characterIndex - 1];
  // const secondCharacter = charactersAssets[characterIndex];
  //
  // operations = [
  //   {
  //     op: "replace",
  //     path: `attributes.character.${firstCharacter.key}`,
  //     value: secondCharacter
  //   },
  //   {
  //     op: "replace",
  //     path: `attributes.character.${secondCharacter.key}`,
  //     value: firstCharacter
  //   }
  // ] as PatchOperation[];
  // dispatch(
  //   scenesActions.patchSceneRequest({
  //     draftId,
  //     sceneId,
  //     operations
  //   })
  // );
  // };

  const onClickTalkingIcon = () => {
    let operations: PatchOperation[] = [];
    charactersAssets.forEach((character) => {
      operations = [
        ...operations,
        {
          op: "replace",
          path: `attributes.character.${character.key}.preset_override.is_talking`,
          value: character.key === assetKey
        }
      ] as PatchOperation[];
    });
    dispatch(
      scenesActions.patchSceneRequest({
        draftId,
        sceneId,
        operations,
        skipRePreview: true
      })
    );
    dispatch(voicesActions.cleanFooterAudio());
  };

  return (
    <H1_FlexRow align="center" gap="8px" width="100%" position="relative">
      <ConditionalRender condition={!!isLayoutCharacterPreset && !isMultipleCharacters}>
        <Tooltip title={avatarEyeTooltip}>
          <>
            <EyeButton
              onClick={onClickAvatarEye}
              // isDisabled={!layoutCharacter}
              startContent={
                <H1_Icon
                  color={theme.gray7}
                  icon={currentCharacterVisible ? "fas fa-eye" : "fas fa-eye-slash"}
                />
              }
              isIconOnly
              size="sm"
              variant="light"
            />
          </>
        </Tooltip>
      </ConditionalRender>
      <ConditionalRender condition={!!isLayoutCharacterPreset && isMultipleCharacters}>
        <TalkingFlexRow
          margin="0 9px 0 0"
          align="center"
          flex="0 0 auto"
          onClick={onClickTalkingIcon}
          $isActive={isCharacterTalking}
        >
          <Circle
            $isActive={isCharacterTalking}
            width="25px"
            height="25px"
            align="center"
            justify="center"
          >
            <H1_Icon
              icon={isCharacterTalking ? "fas fa-volume" : "fas fa-volume-xmark"}
              color={theme.gray1}
              isCursorPointer
            />
          </Circle>
          <H1_TextXs
            margin="0 0 0 3px"
            color={isCharacterTalking ? `${theme.blue4}ff` : `${theme.gray8}33`}
          >
            {characterIndex + " >"}
          </H1_TextXs>
        </TalkingFlexRow>
      </ConditionalRender>
      <EntityBorderFlexRow
        data-auto-id="avatar-drawer-selected-avatar"
        flex="0 0 102px"
        height="48px"
        padding="7px 10px"
        gap="10px"
        align="center"
        $visible={!!currentCharacterVisible}
        onClick={onClickAvatar}
      >
        <CharacterContainerFlexRow overflow="hidden" width="32px" height="32px" flex="0 0 32px">
          <CharacterImg
            src={
              (selectedCharacter?.thumbnails?.page_banner.image as string) ||
              selectedCharacter?.image
            }
          />
        </CharacterContainerFlexRow>
        <NameText ellipsis={{ tooltip: selectedCharacter?.title }}>
          {selectedCharacter?.title}
        </NameText>
      </EntityBorderFlexRow>
      <EntityBorderFlexRow
        flex="0 0 112px"
        height="48px"
        padding="7px 10px"
        gap="5px"
        align="center"
        onClick={onClickVoice}
      >
        <FlagImg src={`https://flagcdn.com/${flagSvg}.svg`} />
        <NameText ellipsis={{ tooltip: selectedVoice?.display_name }}>
          {selectedVoice?.display_name}
        </NameText>
      </EntityBorderFlexRow>
      {/*<ConditionalRender condition={characterIndex !== charactersAssets.length}>*/}
      {/*<RightSwapButtonContainer*/}
      {/*  onClick={onClickSwapAvatars}*/}
      {/*  width="30px"*/}
      {/*  height="30px"*/}
      {/*  flex="0 0 30px"*/}
      {/*  align="center"*/}
      {/*  justify="center"*/}
      {/*>*/}
      {/*  <H1_Icon color={theme.gray7} isCursorPointer icon="fas fa-arrows-retweet" />*/}
      {/*</RightSwapButtonContainer>*/}
      {/*</ConditionalRender>*/}
    </H1_FlexRow>
  );
};

export default ContentSectionCharacter;
