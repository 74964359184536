import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  becomeCharacter,
  deleteCharacter,
  getCharactersList,
  setFavoritesBulk,
  updateCharacterTitle
} from "app/services/serviceV2Apis";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import { BecomeCharacter, Character, Emotion } from "app/types/character";
import { FavoriteType, PatchOperation, ThunkApi } from "app/types";
import { charactersGlobalSelectors, scenesGlobalSelectors } from "app/store/adapters/adapters";
import { charactersActions } from "app/store/slices/characters.slice";
import * as CharacterSelectors from "app/store/selectorsV2/character.selectors";

const prefix = "[Characters]";

export const addSourceId = (character: Character) => ({
  ...character,
  sourceId: `${character.character_source}_${character.character_outfit}_${character.type}_${
    character.shot_type || ""
  }`
});

const getCharactersListRequest = createAsyncThunk<Character[], void>(
  `${prefix} getCharactersListRequest`,
  async () => {
    const result = await getCharactersList();
    return result.map(addSourceId);
  },
  thunkOptions
);

const getMobileCharactersListRequest = createAsyncThunk<Character[], void, ThunkApi>(
  `${prefix} getMobileCharactersListRequest`,
  async () => {
    const result = await getCharactersList({ type: "mobile", scope: "me" });
    return result;
  },
  thunkOptions
);

const becomeCharacterRequest = createAsyncThunk<void, BecomeCharacter>(
  `${prefix} becomeCharacterRequest`,
  async (data) => {
    await becomeCharacter(data);
  },
  thunkOptions
);

const initializeSelectedEmotions = createAsyncThunk<void, void, ThunkApi>(
  `${prefix} initializeSelectedEmotions`,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const allCharacter = charactersGlobalSelectors.selectAll(state);
    const { drafts, scenes } = state;
    if (drafts.currentDraft.global_character) {
      const characterId = drafts.currentDraft.attributes?.character?.character?.character_id;
      if (characterId) {
        const selectedCharacter = allCharacter.find((c) => c.character_id === characterId);
        if (selectedCharacter) {
          thunkAPI.dispatch(charactersActions.setSingleSelectedEmotion(selectedCharacter));
        }
      }
    } else {
      const selectedCharactersEmotions: Record<string, Emotion> = {};
      (scenes.ids as string[]).forEach((sceneId: string) => {
        const currentScene = scenesGlobalSelectors.selectById(state, sceneId);
        const characterId = currentScene?.attributes?.character?.character?.character_id;
        if (characterId) {
          const selectedCharacter = allCharacter.find((c) => c.character_id === characterId);
          if (selectedCharacter) {
            selectedCharactersEmotions[selectedCharacter.sourceId] =
              selectedCharacter.emotion as Emotion;
          }
        }
      });
      thunkAPI.dispatch(charactersActions.setSelectedEmotions(selectedCharactersEmotions));
    }
  },
  thunkOptions
);

const setFavoritesBulkRequest = createAsyncThunk<void, Character, ThunkApi>(
  `${prefix} setFavoritesBulkRequest`,
  async (character: Character, thunkApi) => {
    const charactersByOutfitAndSource = CharacterSelectors.getCharactersBucketByOutfitAndSource(
      thunkApi.getState()
    );
    const favoritesCharacters: Character[] =
      charactersByOutfitAndSource[`${character.sourceId || character.character_source}`];
    const favoritesAssets = favoritesCharacters.map((char) => ({
      asset_id: char.id,
      asset_type: FavoriteType.character,
      active: !character.favorite
    }));
    thunkApi.dispatch(charactersActions.setFavoritesBulk(favoritesAssets));
    await setFavoritesBulk(favoritesAssets);
  },
  thunkOptions
);

const updateCharacterTitleRequest = createAsyncThunk<
  void,
  { id: string; character_id: string; operations: PatchOperation[]; title: string }
>(
  `${prefix} updateCharacterTitleRequest`,
  async ({ character_id, operations }) => {
    await updateCharacterTitle(character_id, operations);
  },
  thunkOptions
);

const deleteCharacterRequest = createAsyncThunk<void, { id: string; character_id: string }>(
  `${prefix} deleteCharacterRequest`,
  async ({ character_id }) => {
    await deleteCharacter(character_id);
  },
  thunkOptions
);

export default {
  getCharactersListRequest,
  getMobileCharactersListRequest,
  becomeCharacterRequest,
  initializeSelectedEmotions,
  setFavoritesBulkRequest,
  updateCharacterTitleRequest,
  deleteCharacterRequest
};
