import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import LayoutDrawer from "app/components/editor/sideDrawers/LayoutDrawer";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import { useIntl } from "react-intl";
import { rightMenuMessages } from "app/pages/editor/messages";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  AssetGroup,
  FeatureFlag,
  INTRO_OUTRO_KEYS,
  PlanEnum,
  SupportedGroups,
  TemplateAsset,
  TemplateAssets,
  TemplateAssetWithId
} from "app/types";
import StyleDrawer from "app/components/editor/sideDrawers/StyleDrawer/StyleDrawer";
import { draftsActions as draftsActionsV2 } from "app/store/slices/drafts.slice";
import AttributeIcon from "app/pages/editor/AttributeIcon";
import VideoDrawer from "app/components/editor/sideDrawers/VideoDrawer";
import EditingProjectVoiceDrawer from "app/components/editor/sideDrawers/VoiceDrawer/EditingProjectVoiceDrawer";
import BrandDrawer from "app/components/editor/sideDrawers/BrandDrawer/BrandDrawer";
import RenderPanelDrawer from "app/components/editor/sideDrawers/RenderPanel/RenderPanelDrawer";
import ExportVideoDrawer from "app/components/editor/sideDrawers/ExportVideoDrawer/ExportVideoDrawer";
import SceneDrawer from "app/components/editor/sideDrawers/SceneDrawer/SceneDrawer";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import EditorBackgroundMusicDrawer from "app/components/editor/sideDrawers/EditorBackgroundMusicDrawer";
import TemplatesDrawer from "app/components/editor/sideDrawers/TemplatesDrawer/TemplatesDrawer";
import { useFlags } from "launchdarkly-react-client-sdk";
import ConditionalRender from "app/components/common/ConditionalRender";
import ImageFitDrawer from "app/components/editor/sideDrawers/SceneDrawer/ImageFit/ImageFitDrawer";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import useSmallScreen from "app/hooks/useSmallScreen";
import { Preferences } from "app/types/preferences";
import MusicDrawerV2 from "app/components/editor/sideDrawers/MusicDrawerV2/MusicDrawerV2";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import CharacterDrawer from "app/components/editor/sideDrawers/CharacterDrawer/CharacterDrawer";
import CommentsDrawer from "app/components/editor/sideDrawers/CommentsDrawer/CommentsDrawer";
import useUpgradeModal, { useShowCrown } from "app/hooks/useUpgradeModal";
import { PlanFeature } from "app/config/planFeature";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import usePermissions from "app/hooks/usePermissions";
import VideoWizardDrawer from "app/components/editor/sideDrawers/VideoWizardDrawer/VideoWizardDrawer";
import { Tooltip } from "antd";
import { workspaceSupportsLiveBlocks } from "app/store/selectorsV2/workspaces.selectors";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";
import FlexDrawer from "app/components/common/Layout/FlexDrawer";
import { CharacterType } from "app/types/character";

export const drawerWidthOffset = "89px";
export const drawerWidth = "380px";

const PaddingRightIcon = styled.i`
  padding-right: 2px;
`;
const RelativeFlexRow = styled(H1_FlexRow)`
  margin-right: 16px;
  border-radius: 20px;
  position: relative;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    flex-direction: column;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.12) 0 2px 10px 0;
    background: white;
    z-index: 1001;
  }
`;

const UpgradeIcon = styled(H1_Icon)`
  position: absolute;
  top: 2px;
  left: 2px;
  &&& {
    color: ${({ theme }) => theme.orange3};
    font-size: 12px;
    width: 12px;
    height: 10px;
  }
`;

const VideoWizardContainer = styled(H1_FlexRow)<{ $isReversedEditor?: boolean }>`
  position: absolute;
  left: ${({ $isReversedEditor }) => ($isReversedEditor ? "23px" : "auto")};
  right: ${({ $isReversedEditor }) => ($isReversedEditor ? "auto" : "23px")};
  bottom: 96px;
`;
const VideoWizardButton = styled(Button)<{ $active: boolean }>`
  width: 40px;
  height: 40px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  background-color: ${({ theme, $active }) =>
    $active ? theme.blue4 : theme.mode === ThemeMode.Light ? theme.blue2 : theme.blue3};
  border-radius: 10px;
  &:hover {
    background: ${({ theme }) => `linear-gradient(151deg, ${theme.blue4} 17.76%, #AAF 103.65%)`};
  }
  &:active {
    background: ${({ theme }) => theme.blue4};
  }
`;

const ButtonsFlexColumn = styled(H1_FlexColumn)<{ $isReversedEditor: boolean }>`
  box-shadow: 0 0 11.33px 0 rgba(0, 0, 0, 0.05);
  border-radius: 20px 20px 0 0;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
  align-items: center;
  padding: 20px 0 30px 0;

  @media (max-width: ${SMALL_SCREEN_PX}) {
    flex-direction: row;
    flex: 0 0 100px;
    padding: 0 70px 0 20px;
    background-color: ${({ theme }) => theme.gray1};
    gap: 10px;
    overflow-x: auto;

    div:first-of-type {
      flex-direction: row;
      width: fit-content;
      gap: 10px;
      margin-bottom: 0;
    }
  }
`;

const RightNavAttributesList = () => {
  const dispatch = useAppDispatch();
  const { selectedSceneIndex, sceneId, scene } = useSelectedScene();
  const { formatMessage } = useIntl();
  const flags = useFlags();
  const { openDrawer, editorDrawerOpen } = useDrawer();
  const { isSmallScreen } = useSmallScreen();
  const showPaymentModal = useUpgradeModal();
  const showCrown = useShowCrown({
    requestedFeature: PlanFeature.Comments
  });
  const { isWriteWorkspaceAllow, isEnterprise } = usePermissions();

  const sceneName = scene?.name as string;
  const voicesDrawerActive = editorDrawerOpen === Drawer.Voices;
  const sceneDrawerActive = editorDrawerOpen === Drawer.Scene;
  const colorsDrawerActive = editorDrawerOpen === Drawer.Colors;
  const presenterDrawerActive = editorDrawerOpen === Drawer.Presenter;
  const musicV2DrawerActive = editorDrawerOpen === Drawer.MusicV2;
  const brandDrawerActive = editorDrawerOpen === Drawer.Brand;
  const videoDrawerActive = editorDrawerOpen === Drawer.Video;
  const templatesDrawerActive = editorDrawerOpen === Drawer.Templates;
  const commentsDrawerActive = editorDrawerOpen === Drawer.Comments;
  const videoWizardDrawerActive = editorDrawerOpen === Drawer.VideoWizard;

  const isLiveBlocksSupported = useAppSelector(workspaceSupportsLiveBlocks);
  const template = useAppSelector((state) => state.templates.currentTemplate);
  const draft = useAppSelector((state) => state.drafts.currentDraft);
  const currentPalettes = useAppSelector((state) => state.templates.currentPalettes);
  const preferences: Preferences = useAppSelector((state) => state.user.preferences);
  const isReversedEditor = preferences.is_reversed_editor;
  const hasStyleButton = !!currentPalettes.length;
  const draftId: string = draft?.id || "";
  const isTemplateTypeCinematic = template?.character_types.includes(CharacterType.cinematic);

  const { attributes = {} } = draft;

  const assetsArr: TemplateAsset[] = useMemo(() => {
    const allValues = Object.values(template ? template.assets : ({} as TemplateAssetWithId));
    return allValues.filter((asset) => !asset.hidden);
  }, [template]);

  const groupedAssets = useMemo(() => {
    const res: Record<string, TemplateAssets> = assetsArr.reduce((acc, curr) => {
      const { group, key } = curr;
      if (!acc[group]) {
        acc[group] = {};
      }

      acc[group][key || "undefined"] = curr;

      return acc;
    }, {} as Record<AssetGroup, any>);

    return res;
  }, [assetsArr]);

  const { hasVideoButton, hasMusicButton, hasBrandButton } = useMemo(
    () => ({
      hasVideoButton: (assetsArr as TemplateAsset[]).some(
        ({ group, key }: TemplateAsset) =>
          group === SupportedGroups.Video &&
          (!flags[FeatureFlag.showBrandKitButton] ||
            !INTRO_OUTRO_KEYS.includes(key?.toLowerCase() as string))
      ),
      hasLogoButton: (assetsArr as TemplateAsset[]).some(
        ({ group }: TemplateAsset) => group === SupportedGroups.Logo
      ),
      hasMusicButton: (assetsArr as TemplateAsset[]).some(
        ({ type }: TemplateAsset) => type === SupportedGroups.Music
      ),
      hasBrandButton: (assetsArr as TemplateAsset[]).some(
        ({ group, key }: TemplateAsset) =>
          group === SupportedGroups.Logo ||
          (group === SupportedGroups.Video &&
            INTRO_OUTRO_KEYS.includes(key?.toLowerCase() as string))
      )
    }),
    [assetsArr, template]
  );

  useEffect(() => {
    if (!isSmallScreen) {
      if (!isWriteWorkspaceAllow && isEnterprise) {
        openDrawer(Drawer.Comments);
      } else {
        openDrawer(Drawer.Scene);
      }
    }
  }, [isSmallScreen]);

  const showColorsMenu = () => {
    dispatch(
      analyticsEvents.rightMenuClick({
        selection: Drawer.Colors,
        selectedScene: { name: sceneName, index: selectedSceneIndex + 1, id: sceneId }
      })
    );
    openDrawer(Drawer.Colors);
  };

  const showVoicesMenu = () => {
    dispatch(
      analyticsEvents.rightMenuClick({
        selection: Drawer.Voices,
        selectedScene: { name: sceneName, index: selectedSceneIndex + 1, id: sceneId }
      })
    );
    openDrawer(Drawer.Voices);
  };
  const showSceneMenu = () => {
    dispatch(
      analyticsEvents.rightMenuClick({
        selection: Drawer.Scene,
        selectedScene: { name: sceneName, index: selectedSceneIndex + 1, id: sceneId }
      })
    );
    openDrawer(Drawer.Scene);
  };
  const showPresenterMenu = () => {
    dispatch(
      analyticsEvents.rightMenuClick({
        selection: Drawer.Presenter,
        selectedScene: { name: sceneName, index: selectedSceneIndex + 1, id: sceneId }
      })
    );
    openDrawer(Drawer.Presenter);
  };
  const showMusicMenu = () => {
    dispatch(
      analyticsEvents.rightMenuClick({
        selection: Drawer.Music,
        selectedScene: { name: sceneName, index: selectedSceneIndex + 1, id: sceneId }
      })
    );
    openDrawer(flags[FeatureFlag.musicv2] ? Drawer.MusicV2 : Drawer.Music);
  };

  const showBrandMenu = () => {
    dispatch(
      analyticsEvents.rightMenuClick({
        selection: Drawer.Brand,
        selectedScene: { name: sceneName, index: selectedSceneIndex + 1, id: sceneId }
      })
    );
    openDrawer(Drawer.Brand);
  };

  const showVideoMenu = () => {
    dispatch(
      analyticsEvents.rightMenuClick({
        selection: Drawer.Video,
        selectedScene: { name: sceneName, index: selectedSceneIndex + 1, id: sceneId }
      })
    );
    openDrawer(Drawer.Video);
  };

  const showTemplatesMenu = () => {
    dispatch(
      analyticsEvents.rightMenuClick({
        selection: Drawer.Templates,
        selectedScene: { name: sceneName, index: selectedSceneIndex + 1, id: sceneId }
      })
    );
    openDrawer(Drawer.Templates);
  };

  const showVideoWizardMenu = () => {
    dispatch(
      analyticsEvents.rightMenuClick({
        selection: Drawer.VideoWizard,
        selectedScene: { name: sceneName, index: selectedSceneIndex + 1, id: sceneId }
      })
    );
    openDrawer(Drawer.VideoWizard);
  };

  const showCommentsMenu = () => {
    dispatch(
      analyticsEvents.rightMenuClick({
        selection: Drawer.Comments,
        selectedScene: { name: sceneName, index: selectedSceneIndex + 1, id: sceneId }
      })
    );
    if (
      !showPaymentModal({
        source: "upgrade_comments",
        upgradeText: formatMessage(rightMenuMessages.upgradeComments),
        requestedFeature: PlanFeature.Comments,
        targetPlan: PlanEnum.enterprise
      })
    ) {
      openDrawer(Drawer.Comments);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDraftAttributeChange = (path: string, value: any, skipRePreview = false) => {
    dispatch(
      draftsActionsV2.patchDraftRequest({
        draftId,
        operations: [{ op: value ? "replace" : "delete", path, value }],
        skipRePreview
      })
    );
  };

  return (
    <RelativeFlexRow
      flex="1 1 auto"
      height="100%"
      justify={isReversedEditor ? "flex-start" : "flex-end"}
    >
      <FlexDrawer width={drawerWidth} right={drawerWidthOffset} active />
      <LayoutDrawer right={drawerWidthOffset} width={drawerWidth} />
      {/*<EditingCharacterCustomizeDrawer right={drawerWidthOffset} width={drawerWidth} />*/}
      <CharacterDrawer right={drawerWidthOffset} width={drawerWidth} />
      <EditingProjectVoiceDrawer right={drawerWidthOffset} width={drawerWidth} />
      <EditorBackgroundMusicDrawer
        right={drawerWidthOffset}
        width={drawerWidth}
        onDraftAttributeChange={onDraftAttributeChange}
      />
      <MusicDrawerV2
        right={drawerWidthOffset}
        width={drawerWidth}
        onDraftAttributeChange={onDraftAttributeChange}
      />
      <StyleDrawer
        width={drawerWidth}
        right={drawerWidthOffset}
        onDraftAttributeChange={onDraftAttributeChange}
        fieldsData={groupedAssets[SupportedGroups.Style]}
        attributes={attributes}
      />

      <SceneDrawer right={drawerWidthOffset} width={drawerWidth} />
      <VideoWizardDrawer right={drawerWidthOffset} width={drawerWidth} />
      <VideoDrawer
        right={drawerWidthOffset}
        onDraftAttributeChange={onDraftAttributeChange}
        width={drawerWidth}
        fieldsData={groupedAssets[SupportedGroups.Video]}
        attributes={attributes}
      />
      <ImageFitDrawer right={drawerWidthOffset} width={drawerWidth} />
      <BrandDrawer
        width={drawerWidth}
        right={drawerWidthOffset}
        onDraftAttributeChange={onDraftAttributeChange}
        assetsArr={assetsArr}
        attributes={attributes}
      />
      <TemplatesDrawer width={drawerWidth} right={drawerWidthOffset} />
      <ConditionalRender condition={isLiveBlocksSupported}>
        <CommentsDrawer width={drawerWidth} right={drawerWidthOffset} />
      </ConditionalRender>

      <RenderPanelDrawer right={drawerWidthOffset} width={drawerWidth} />
      <ExportVideoDrawer right={drawerWidthOffset} width={drawerWidth} />

      <ButtonsFlexColumn
        gap="16px"
        $isReversedEditor={!!isReversedEditor}
        flex={`0 0 ${drawerWidthOffset}`}
      >
        <ConditionalRender condition={isWriteWorkspaceAllow && !isTemplateTypeCinematic}>
          <H1_FlexColumn gap="16px" data-auto-id="editor-right-menu-presenter-voices-container">
            <AttributeIcon
              dataAutoId="editor-right-menu-presenter-button"
              title={formatMessage(rightMenuMessages.presenter)}
              onClick={showPresenterMenu}
              drawerActive={presenterDrawerActive}
            >
              <i className="fas fa-user" />
            </AttributeIcon>
            <AttributeIcon
              dataAutoId="editor-right-menu-voices-button"
              title={formatMessage(rightMenuMessages.voices)}
              onClick={showVoicesMenu}
              drawerActive={voicesDrawerActive}
            >
              <i className="fas fa-microphone" />
            </AttributeIcon>
          </H1_FlexColumn>
        </ConditionalRender>
        <ConditionalRender condition={isWriteWorkspaceAllow}>
          <AttributeIcon
            dataAutoId="editor-right-menu-scene-button"
            title={formatMessage(rightMenuMessages.scene)}
            onClick={showSceneMenu}
            drawerActive={sceneDrawerActive}
          >
            <i className="fas fa-image-polaroid" />
          </AttributeIcon>
        </ConditionalRender>
        <ConditionalRender condition={isWriteWorkspaceAllow}>
          <AttributeIcon
            dataAutoId="editor-right-menu-style-button"
            title={formatMessage(rightMenuMessages.colors)}
            onClick={showColorsMenu}
            drawerActive={colorsDrawerActive}
            isHidden={!hasStyleButton}
          >
            <i className="fas fa-palette" />
          </AttributeIcon>
        </ConditionalRender>
        <ConditionalRender
          condition={flags[FeatureFlag.showBrandKitButton] && isWriteWorkspaceAllow}
        >
          <AttributeIcon
            dataAutoId="editor-right-menu-brand-button"
            title={formatMessage(rightMenuMessages.brand)}
            onClick={showBrandMenu}
            drawerActive={brandDrawerActive}
            isHidden={!hasBrandButton}
          >
            <i className="fas fa-swatchbook" />
          </AttributeIcon>
        </ConditionalRender>
        <ConditionalRender condition={isWriteWorkspaceAllow}>
          <AttributeIcon
            dataAutoId="editor-right-menu-background-music-button"
            title={formatMessage(rightMenuMessages.music)}
            onClick={showMusicMenu}
            drawerActive={musicV2DrawerActive}
            isHidden={!hasMusicButton}
          >
            <PaddingRightIcon className="fas fa-music" />
          </AttributeIcon>
        </ConditionalRender>
        <ConditionalRender condition={isWriteWorkspaceAllow}>
          <AttributeIcon
            dataAutoId="editor-right-menu-video-button"
            title={formatMessage(rightMenuMessages.video)}
            onClick={showVideoMenu}
            drawerActive={videoDrawerActive}
            isHidden={!hasVideoButton}
          >
            <i className="fas fa-video" />
          </AttributeIcon>
        </ConditionalRender>
        <ConditionalRender
          condition={
            flags[FeatureFlag.changeTemplate] && isWriteWorkspaceAllow && !isTemplateTypeCinematic
          }
        >
          <AttributeIcon
            dataAutoId="editor-right-menu-templates-button"
            title={formatMessage(rightMenuMessages.templates)}
            onClick={showTemplatesMenu}
            drawerActive={templatesDrawerActive}
          >
            <i className="fas fa-objects-column" />
          </AttributeIcon>
        </ConditionalRender>
        <ConditionalRender condition={isWriteWorkspaceAllow || isEnterprise}>
          <AttributeIcon
            dataAutoId="editor-right-menu-comments-button"
            title={formatMessage(rightMenuMessages.comments)}
            onClick={showCommentsMenu}
            drawerActive={commentsDrawerActive}
          >
            <>
              <i className="fas fa-comments" />
              <ConditionalRender condition={showCrown}>
                <UpgradeIcon icon="fas fa-crown" />
              </ConditionalRender>
            </>
          </AttributeIcon>
        </ConditionalRender>
      </ButtonsFlexColumn>
      <Tooltip placement="left" title={formatMessage(rightMenuMessages.videoWizard)}>
        <VideoWizardContainer $isReversedEditor={!!isReversedEditor}>
          <VideoWizardButton
            color="primary"
            data-auto-id="editor-right-menu-video-wizard"
            $active={videoWizardDrawerActive}
            onClick={showVideoWizardMenu}
            isIconOnly
            startContent={<i className="fas fa-sparkles" />}
          />
        </VideoWizardContainer>
      </Tooltip>
    </RelativeFlexRow>
  );
};

export default React.memo(RightNavAttributesList);
