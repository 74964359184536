import DevModePopover from "app/components/DevModePopover";
import ConditionalRender from "app/components/common/ConditionalRender";
import PreviewTag from "app/components/common/PreviewTag";
import { characterDrawerMessages } from "app/components/editor/sideDrawers/CharacterDrawer/messages";
import { Character, CharacterType } from "app/types/character";
import { Tooltip } from "antd";
import { useAppDispatch, useAppSelector } from "app/hooks";
import * as characterSelectors from "app/store/selectorsV2/character.selectors";
import * as CharacterSelectors from "app/store/selectorsV2/character.selectors";
import { countCharactersBySourceId } from "app/store/selectorsV2/character.selectors";
import { fetchingStatus, getEmotionEmojiText } from "app/utils/helpers";
import styled, { useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { ReactComponent as CrownIcon } from "app/assets/editor/crown-white-icon.svg";
import { useIntl } from "react-intl";
import usePlansConverter from "app/hooks/usePlansConverter";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import VideoWithHover from "app/components/common/VideoWithHover";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { charactersActions } from "app/store/slices/characters.slice";
import React from "react";
import { charactersGlobalSelectors, voicesGlobalSelectors } from "app/store/adapters/adapters";
import { Card, CardBody, CardFooter } from "@nextui-org/react";
import { Draft, PatchOperation, Voice, VoiceType } from "app/types";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { voicesActions } from "app/store/slices/voices.slice";
import { draftsActions as draftsActionsV2 } from "app/store/slices/drafts.slice";
import { scenesActions } from "app/store/slices/scenes.slice";
import useUpgradeModal from "app/hooks/useUpgradeModal";
import usePermissions from "app/hooks/usePermissions";
import * as voiceSelectors from "app/store/selectorsV2/voices.selectors";
import { ThemeMode } from "app/utils/theme";

const StarIconFlexRow = styled(H1_FlexRow)<{ $visible: boolean }>`
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
`;

const IconsFlexRow = styled(H1_FlexRow)`
  right: 6px;
  bottom: 10px;
  z-index: 600;
`;

const GesturesFlexRow = styled(H1_FlexRow)`
  right: 8px;
  top: 8px;
  z-index: 600;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  z-index: 3;
  &:hover {
    transform: scale(1.1);
  }
`;

const GestureIcon = styled(H1_Icon)`
  position: relative;
  transition: transform 0.3s ease-in-out;
  font-size: 16px;
  padding: 3px;
  background-color: ${({ theme }) => theme.gray3};
  border-radius: 4px;
`;

const StarIcon = styled(H1_Icon)`
  position: relative;
  transition: transform 0.3s ease-in-out;
  background: ${({ theme }) => theme.gray2};
  color: transparent; // Makes the font icon transparent
  height: 20px;
  width: 20px;
  padding: 3px;
  border-radius: 3px;
  font-size: 14px;

  &::before {
    position: absolute;
    top: 3px;
    left: 3px;
    right: 0;
    bottom: 0;
    background-image: ${({ theme }) =>
      `linear-gradient(134deg, ${theme.blue4} 21.54%, rgba(255, 255, 255, 0.00) 121.01%)`};
    background-clip: text;
    color: transparent;
  }
  &:hover {
    transform: scale(1.1);
  }
`;

const StyledCard = styled(Card)<{ $selected: boolean }>`
  border: 1px solid ${({ theme, $selected }) => ($selected ? theme.blue4 : theme.gray3)};
  transition: border-color 0.5s ease-in-out;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
  &&&:hover {
    --tw-shadow: var(--nextui-box-shadow-small);
    --tw-shadow-colored: var(--nextui-box-shadow-small);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
      var(--tw-shadow);
  }
  &&&:active {
    --tw-shadow: var(--nextui-box-shadow-none);
    --tw-shadow-colored: var(--nextui-box-shadow-none);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
      var(--tw-shadow);
  }
`;

const BoxCharacterFlexColumn = styled(H1_FlexColumn)<{ $greyedOut?: boolean }>`
  border-radius: 5px;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
  opacity: ${(props) => props.$greyedOut && "50%"};
  &:hover {
    video {
      transform: scale(1.05);
    }
    ${StarIconFlexRow} {
      opacity: 1;
    }
    ${GesturesFlexRow} {
      opacity: 1;
    }
  }
  &:active {
    transform: scale(1);
  }
  z-index: 500;
  /* In order to show the menu above other characters */
  &:has(.ant-select-open) {
    z-index: 501;
  }
`;

const PreviewTagContainer = styled.div<{ left?: boolean; bottom?: boolean }>`
  position: absolute;
  right: ${({ left }) => (left ? "auto" : "4px")};
  left: ${({ left }) => (left ? "4px" : "auto")};
  top: ${({ bottom }) => (bottom ? "auto" : "4px")};
  bottom: ${({ bottom }) => (bottom ? "38px" : "auto")};
  text-transform: uppercase;
  display: flex;
  z-index: 2;
`;

const WidthTextSmall = styled(H1_TextSmall)`
  max-width: 105px;
  align-self: center;
`;

const CharImage = styled(VideoWithHover)`
  max-height: 133px;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
`;

const BackgroundContainer = styled(H1_FlexRow)`
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray2 : theme.gray3)};
  max-width: 141px;
  border-radius: 0 0 16px 16px;
`;

interface CharacterDrawerSingleProps {
  characterAssetKey: string;
  voiceAssetKey: string;
  characterId: string;
  isTemplateSupported: boolean;
  onDisableAnimation: () => void;
}
const CharacterDrawerSingle = ({
  characterId,
  characterAssetKey,
  voiceAssetKey,
  isTemplateSupported,
  onDisableAnimation
}: CharacterDrawerSingleProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const intl = useIntl();
  const plansConverter = usePlansConverter();
  const showPaymentModal = useUpgradeModal();
  const { isWriteWorkspaceAllow } = usePermissions();
  const isGestureSelected = !!useAppSelector((state) => state.characters.gestureSourceId);
  const character = useAppSelector((state) =>
    charactersGlobalSelectors.selectById(state, characterId)
  ) as Character;
  const draftGlobalCharacterStatus = useAppSelector(
    (state) => state.drafts.draftGlobalCharacterStatus
  );
  const developerMode = useAppSelector((state) => state.uiV2.developerMode);
  const selectedCharacter = useAppSelector((state) =>
    characterSelectors.getDrawerCharacterBySelectedScene(state, characterAssetKey)
  );
  const currentTemplate = useAppSelector(CharacterSelectors.template);
  const characterGesturesCounter = useAppSelector((state) =>
    countCharactersBySourceId(state, character.sourceId)
  );
  const sceneId = useAppSelector((state) => state.scenes.selectedSceneId);
  const selectedVoice: Voice | undefined = useAppSelector((state) =>
    voiceSelectors.getSelectedVoiceForDrawer(state, voiceAssetKey)
  );
  const allCharacters = useAppSelector(charactersGlobalSelectors.selectAll);
  const allVoices = useAppSelector(voicesGlobalSelectors.selectAll);
  const currentDraft = useAppSelector((state) => state.drafts.currentDraft) as Draft;

  const isApplyAllScenes = currentDraft?.global_character;
  const isDraftGlobalCharacterLoading = draftGlobalCharacterStatus === fetchingStatus.loading;
  if (!character) {
    return null;
  }

  const onSelectCharacter = async (characterId: string) => {
    if (isDraftGlobalCharacterLoading) {
      return;
    }
    const chosenCharacter = allCharacters.find(
      (currentCharacter) => currentCharacter.character_id === characterId
    );

    const genderedVoicesIds = allVoices
      .filter((curVoice) => curVoice.gender === chosenCharacter?.gender)
      .map((curVoice) => curVoice.id);
    let newVoiceId: string | undefined;
    if (
      !genderedVoicesIds.includes(selectedVoice?.id as string) &&
      genderedVoicesIds.length &&
      selectedVoice?.type !== VoiceType.cloned
    ) {
      [newVoiceId] = genderedVoicesIds;
    }

    dispatch(
      googleEvents.chooseCharacter({
        id: characterId,
        name: chosenCharacter?.title as string,
        gender: chosenCharacter?.gender as string,
        pro: chosenCharacter?.pro as boolean
      })
    );

    const upgradeRequired = showPaymentModal({
      source: "upgrade_character_drawer",
      upgradeText: intl.formatMessage(characterDrawerMessages.upgradeCharacter),
      requestedPlans: chosenCharacter?.plans ?? []
    });
    if (!upgradeRequired) {
      dispatch(charactersActions.setSingleSelectedEmotion(chosenCharacter));
      onCharacterChange(newVoiceId, characterId);
      dispatch(charactersActions.setGestureCharacter(undefined));
    }
  };

  const onCharacterChange = (voiceId: string | undefined, characterId: string) => {
    if (!isWriteWorkspaceAllow) {
      return;
    }

    const characterIdOperation: PatchOperation = {
      op: "replace",
      path: `attributes.character.${characterAssetKey}.character_id`,
      value: characterId
    };

    const operations: PatchOperation[] = [characterIdOperation];
    if (voiceId) {
      // todo this is for the case that there is no default voiceId.
      operations.push({
        op: "replace",
        path: `attributes.voice.${voiceAssetKey}.voice_id`,
        value: voiceId
      } as PatchOperation);
      dispatch(voicesActions.cleanFooterAudio());
    }

    if (isApplyAllScenes) {
      dispatch(
        draftsActionsV2.patchDraftRequest({
          draftId: currentDraft?.id as string,
          operations
        })
      );
    } else if (!isApplyAllScenes) {
      dispatch(
        scenesActions.patchSceneRequest({
          draftId: currentDraft?.id as string,
          sceneId: sceneId as string,
          operations
        })
      );
    }
  };

  const onClickGestures = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    dispatch(
      analyticsEvents.characterGestures({
        value: character.sourceId,
        characterId: character.id,
        source: "drawerBody"
      })
    );
    dispatch(charactersActions.setGestureCharacter(character.sourceId));
    dispatch(charactersActions.setSingleSelectedEmotion(character));
  };
  const onClickFavorite = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    onDisableAnimation();
    dispatch(
      analyticsEvents.changeCharacterFavorite({
        value: !character.favorite,
        characterId: character.id,
        source: "drawerBody"
      })
    );
    dispatch(charactersActions.setFavoritesBulkRequest(character));
  };

  const onPress = () => {
    if (isTemplateSupported) {
      onSelectCharacter(character.character_id);
    }
  };

  return (
    <BoxCharacterFlexColumn
      align="center"
      flex="0 0 auto"
      width="155px"
      height="186px"
      padding="10px 7px"
      gap="10px"
      position="relative"
      $greyedOut={!isTemplateSupported || isDraftGlobalCharacterLoading}
    >
      <StyledCard
        $selected={character.id === selectedCharacter?.id}
        shadow="none"
        isPressable
        onPress={onPress}
      >
        <CardBody className="overflow-visible p-0 static">
          <DevModePopover placement="left" value={{ character_id: character.id }} path="root" />
          <ConditionalRender condition={isTemplateSupported && !isGestureSelected}>
            <IconsFlexRow align="center" position="absolute" gap="5px">
              <StarIconFlexRow onClick={onClickFavorite} $visible={character.favorite}>
                <StarIcon
                  size="13px"
                  isCursorPointer
                  icon={character.favorite ? "fas fa-star" : "far fa-star"}
                  color={theme.gray7}
                />
              </StarIconFlexRow>
            </IconsFlexRow>
            <ConditionalRender condition={!isGestureSelected && characterGesturesCounter > 1}>
              <GesturesFlexRow align="center" position="absolute">
                <H1_FlexRow onClick={onClickGestures}>
                  <Tooltip title={intl.formatMessage(characterDrawerMessages.gesture)}>
                    <GestureIcon
                      size="13px"
                      isCursorPointer
                      icon="far fa-hands-clapping"
                      color={theme.gray7}
                    />
                  </Tooltip>
                </H1_FlexRow>
              </GesturesFlexRow>
            </ConditionalRender>
          </ConditionalRender>
          <ConditionalRender condition={character.tailor_made}>
            <PreviewTagContainer left>
              <PreviewTag background={theme.orange3}>
                {intl.formatMessage(characterDrawerMessages.myAvatar)}
              </PreviewTag>
            </PreviewTagContainer>
          </ConditionalRender>
          <ConditionalRender condition={!developerMode && isTemplateSupported && character.pro}>
            <PreviewTagContainer bottom left>
              <PreviewTag icon={<CrownIcon />} background={theme.orange3}>
                {plansConverter(character?.plans?.[0])}
                {currentTemplate?.character_types &&
                  !currentTemplate?.character_types?.includes(character.type as CharacterType)}
              </PreviewTag>
            </PreviewTagContainer>
          </ConditionalRender>
          <ConditionalRender
            condition={!developerMode && !character.pro && !!character.tags?.length}
          >
            <PreviewTagContainer>
              <PreviewTag background={theme.pink4}>{character?.tags?.[0]}</PreviewTag>
            </PreviewTagContainer>
          </ConditionalRender>
          <Tooltip
            title={
              !isTemplateSupported
                ? intl.formatMessage(characterDrawerMessages.unsupportedCharacter)
                : ""
            }
          >
            <ConditionalRender condition={!!character.thumbnails}>
              <BackgroundContainer width="155px" height="121px" justify="center" overflow="hidden">
                <CharImage
                  video={character.thumbnails?.panel.video as string}
                  image={character.thumbnails?.panel.image as string}
                  disableIcon
                  shouldRestartOnLeave
                />
              </BackgroundContainer>
            </ConditionalRender>
            <ConditionalRender condition={!character.thumbnails}>
              <BackgroundContainer width="170px" height="121px" justify="center" overflow="hidden">
                <CharImage
                  video={character.video}
                  image={character.image}
                  disableIcon
                  shouldRestartOnLeave
                />
              </BackgroundContainer>
            </ConditionalRender>
          </Tooltip>
        </CardBody>
        <CardFooter className="px-2 py-0 h-11">
          <H1_FlexRow height="44px" align="center">
            <WidthTextSmall color={theme.gray7} textAlign="left" ellipsis>
              {isGestureSelected
                ? getEmotionEmojiText(intl, character.emotion) || character.title
                : character.title}
            </WidthTextSmall>
          </H1_FlexRow>
        </CardFooter>
      </StyledCard>
    </BoxCharacterFlexColumn>
  );
};

export default CharacterDrawerSingle;
