import React, { useEffect, useMemo, useState } from "react";
import * as characterSelectors from "app/store/selectorsV2/character.selectors";
import "app/components/editor/Editor.scss";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { DevModeSize } from "app/types";
import {
  Character,
  CharacterFiltersProperties,
  CharacterType,
  defaultCharacterFilter
} from "app/types/character";
import FlexDrawer from "app/components/common/Layout/FlexDrawer";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import styled, { css, useTheme } from "styled-components";
import VideoWithHover from "app/components/common/VideoWithHover";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import DevModePopover from "app/components/DevModePopover";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import CharacterDrawerFilters from "app/components/editor/sideDrawers/CharacterDrawer/CharacterDrawerFilters";
import { characterDrawerMessages } from "app/components/editor/sideDrawers/CharacterDrawer/messages";
import { H1_Input } from "app/components/_Infrastructure/design-system/input";
import { useIntl } from "react-intl";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { NavigationSwiper } from "app/components/common/NavigationSwiper";
import { SwiperSlide } from "swiper/react";
import { charactersActions } from "app/store/slices/characters.slice";
import ConditionalRender from "app/components/common/ConditionalRender";
import CharacterDrawerList from "app/components/editor/sideDrawers/CharacterDrawer/CharacterDrawerList";
import { useDebounce } from "@react-hook/debounce";
import ApplyToScenes from "app/components/editor/sideDrawers/ApplyToScenes";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { getEmotionEmojiText } from "app/utils/helpers";
import { Divider } from "antd";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import GoBackButton from "app/components/common/GoBackButton";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";

const CharImage = styled(VideoWithHover)`
  height: 182px;
  object-fit: cover;
  position: relative;
  bottom: 0px;
`;

const NoMarginTopDivider = styled(Divider)`
  && {
    margin-top: 2px;
  }
`;

const IconsFlexRow = styled(H1_FlexRow)`
  right: 6px;
  bottom: 6px;
  z-index: 600;
`;

const EmotionFlexRow = styled(H1_FlexRow)`
  left: 10px;
  top: 6px;
  z-index: 600;
`;

const ColorIcon = styled(H1_Icon)`
  color: #71717a;
`;
const OpacFlexRow = styled(H1_FlexRow)`
  transition: all 0.3s ease-in-out;
  opacity: 0;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.3);
`;

const SelectedCharacterFogFlexRow = styled(H1_FlexRow)`
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
`;

const StarIcon = styled(H1_Icon)`
  position: relative;
  transition: transform 0.3s ease-in-out;
  background: ${({ theme }) => theme.gray2};
  color: transparent; // Makes the font icon transparent
  color: ${({ theme }) =>
    `linear-gradient(134deg, ${theme.blue4} 21.54%, rgba(255, 255, 255, 0.00) 121.01%)`};
  height: 20px;
  width: 20px;
  padding: 3px;
  border-radius: 3px;

  &::before {
    position: absolute;
    top: 3px;
    left: 3px;
    right: 0;
    bottom: 0;
    background-image: ${({ theme }) =>
      `linear-gradient(134deg, ${theme.blue4} 21.54%, rgba(255, 255, 255, 0.00) 121.01%)`};
    background-clip: text;
    color: transparent;
  }
  &:hover {
    transform: scale(1.1);
  }
`;

const SmallPreviewFlexRow = styled(H1_FlexRow)<{ $isGestureSelected: boolean }>`
  border: none;
  border-radius: 16px;
  ${({ $isGestureSelected }) =>
    !$isGestureSelected &&
    css`
      &:hover {
        ${SelectedCharacterFogFlexRow} {
          opacity: 1;
          filter: brightness(80%);
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 231.65%);
        }
        ${OpacFlexRow} {
          opacity: 1;
          i {
            color: white;
          }
        }
      }
    `};
`;

const BackgroundContainer = styled(H1_FlexRow)`
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray2 : theme.gray3)};
`;

const MagnifyingGlassIcon = styled.i`
  position: absolute;
  z-index: 2;
  font-size: 12px;
  top: 10px;
  left: 10px;
  color: ${({ theme }) => theme.gray7};
`;

const SlidersButton = styled(Button)<{ $active: boolean }>`
  position: absolute;
  right: 0;
  z-index: 2;
  i {
    color: ${({ theme, $active }) =>
      $active ? (theme.mode === ThemeMode.Light ? theme.blue4 : theme.blue2) : theme.gray11};
  }
`;

const StyledSwiper = styled(NavigationSwiper)`
  position: static;
  overflow: hidden;
  display: flex;
  width: 100%;
  .swiper-slide {
    display: flex;
    width: auto;
    align-items: center;
  }
  .swiper-button-prev,
  .swiper-button-next {
    box-shadow: none;
    background-color: transparent;
    border: none;
    top: calc(50% + 2px);
    bottom: 20px;
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.gray7};
    background-size: 24px;
    transition: 0.3s all ease-in-out;
    z-index: 100;
    opacity: 0;
    background-image: none;
    &:hover {
      background-size: 30px;
    }
    &:active {
      background-size: 20px;
    }
  }
  .swiper-button-prev {
    left: -10px;

    &:before {
      position: absolute;
      font-family: "FontAwesome";
      top: 0;
      left: 10px;
      content: "\f053";
    }
  }
  .swiper-button-next {
    right: 0;
    &:before {
      position: absolute;
      font-family: "FontAwesome";
      top: 0;
      right: 0;
      content: "\f054";
    }
  }
  .swiper-button-prev:not(.swiper-button-disabled),
  .swiper-button-next:not(.swiper-button-disabled) {
    opacity: 1;
    background: rgba(255, 255, 255, 0.75);
  }
`;

const Box = styled(H1_FlexRow)<{ $isColored: boolean }>`
  border-radius: 50px;
  border: 1px solid ${({ theme, $isColored }) => ($isColored ? theme.gray7 : theme.gray3)};
  background-color: ${({ theme }) => theme.gray1};
  transition: transform 0.3s ease-in-out;
  &&& span {
    color: ${({ theme }) => theme.gray7};
  }
  &:hover {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  }
  &:active {
    box-shadow: none;
    transform: scale(0.95);
  }
`;

const StyledInput = styled(H1_Input)`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 23px;
  input {
    background-color: ${({ theme }) => theme.gray3};
    border-radius: 4px;
    border: 1px solid transparent;
    padding-right: 58px;
    padding-left: 30px;
    min-width: 100%; /** Bug that should be fixed in the design system */
  }
  i {
    right: 36px;
  }
`;

export interface CharacterDrawerProps {
  right: string;
  width: string;
}

const CharacterDrawer = ({ right, width }: CharacterDrawerProps) => {
  const [searchValue, setSearchValue] = useDebounce<string>("", 750);
  const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(false);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { editorDrawerOpen, originContext, openDrawer } = useDrawer();
  const characterAssetKey = originContext?.assetKey || "character"; //default character;
  const shotType = originContext?.shotType;
  const voiceAssetKey = originContext?.voiceAssetKey || "voice"; //default character;

  const theme = useTheme();
  const { scene } = useSelectedScene();
  const filters = useAppSelector((state) => state.characters.filters);

  const selectedCharacter = useAppSelector((state) =>
    characterSelectors.getDrawerCharacterBySelectedScene(state, characterAssetKey)
  );
  const isGestureSelected = !!useAppSelector((state) => state.characters.gestureSourceId);

  const isDrawerActive = editorDrawerOpen === Drawer.Presenter;
  const charactersAssets = scene?.layout.assets.character || [];
  const currentCharacterIndex =
    charactersAssets.findIndex((character) => character.key === characterAssetKey) + 1;

  const { type: filterType } = filters;

  useEffect(() => {
    if (!isDrawerActive) {
      dispatch(charactersActions.setGestureCharacter(undefined));
    }
  }, [isDrawerActive]);

  const isFilterActive = useMemo(
    () =>
      defaultCharacterFilter.clothing !== filters.clothing ||
      defaultCharacterFilter.age !== filters.age ||
      defaultCharacterFilter.gender !== filters.gender ||
      defaultCharacterFilter.publicAvatars !== filters.publicAvatars ||
      defaultCharacterFilter.myAvatars !== filters.myAvatars ||
      defaultCharacterFilter.pro !== filters.pro ||
      defaultCharacterFilter.free !== filters.free,
    [filters]
  );

  const characterTypes = useMemo(() => Object.keys(CharacterType), []);
  const characterTypesText = useMemo(
    () => ({
      [CharacterType.all]: intl.formatMessage(characterDrawerMessages.all),
      [CharacterType.desk]: intl.formatMessage(characterDrawerMessages.desk),
      [CharacterType.mobile]: intl.formatMessage(characterDrawerMessages.mobile),
      [CharacterType.reals]: intl.formatMessage(characterDrawerMessages.studio),
      [CharacterType.cinematic]: intl.formatMessage(characterDrawerMessages.cinematic),
      [CharacterType.webcam]: intl.formatMessage(characterDrawerMessages.webCam)
    }),
    []
  );

  const onSearchChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
    dispatch(
      analyticsEvents.avatarPanelSearch({
        value
      })
    );
  };

  const onOpenSliders = () => {
    setIsFiltersVisible(!isFiltersVisible);
  };

  const onCloseSliders = () => {
    setTimeout(() => {
      // If clicking on the filters button in order to close, this happens before, closes the filters, and then the button reopens it.
      // Hench the setTimeout to prevent this
      setIsFiltersVisible(false);
    }, 100);
  };

  const onSelectSection = (section: string) => {
    if (filterType?.includes(section as CharacterType) || section === CharacterType.all) {
      dispatch(charactersActions.removeFilter(CharacterFiltersProperties.type));
    } else {
      dispatch(
        analyticsEvents.changeFilterType({
          value: section
        })
      );
      dispatch(charactersActions.setFilters({ type: section }));
    }
  };

  const onClickGestures = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    dispatch(
      analyticsEvents.characterGestures({
        value: selectedCharacter?.sourceId,
        characterId: selectedCharacter?.id,
        source: "drawerBody-preview"
      })
    );
    dispatch(
      charactersActions.setGestureCharacter(
        isGestureSelected ? undefined : selectedCharacter?.sourceId
      )
    );
  };

  const onClickFavorite = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    dispatch(
      analyticsEvents.changeCharacterFavorite({
        value: !selectedCharacter?.favorite,
        characterId: selectedCharacter?.id as string,
        source: "drawerSelected"
      })
    );
    dispatch(charactersActions.setFavoritesBulkRequest(selectedCharacter as Character));
  };

  const onClickBackToScene = () => {
    openDrawer(Drawer.Scene);
  };

  const onRemoveGesture = () => {
    dispatch(charactersActions.setGestureCharacter(undefined));
  };

  return (
    <H1_FlexColumn>
      <FlexDrawer right={right} active={isDrawerActive} width={width}>
        <H1_FlexColumn padding="0 22px 0 0" overflow="hidden" position="relative" flex="1">
          <H1_FlexRow flex="0 0 30px" margin="0 0 10px 0" align="center">
            <GoBackButton
              onClick={isGestureSelected ? onRemoveGesture : onClickBackToScene}
              fontSize="14px"
              label={
                isGestureSelected
                  ? intl.formatMessage(characterDrawerMessages.backToAllCharacters)
                  : intl.formatMessage(characterDrawerMessages.backToScene)
              }
            />
            <ConditionalRender condition={charactersAssets.length > 1}>
              <H1_TextSmall>
                {intl.formatMessage(characterDrawerMessages.avatarNumber, {
                  number: currentCharacterIndex > 0 ? currentCharacterIndex : 1
                })}
              </H1_TextSmall>
            </ConditionalRender>
          </H1_FlexRow>
          <H1_FlexRow flex="0 0 auto" position="relative">
            <StyledInput
              placeholder={intl.formatMessage(characterDrawerMessages.searchCharacterPlaceholder)}
              onChange={onSearchChanged}
              allowClear
              onClear={() => setSearchValue("")}
              initialValue={searchValue}
              isFixedWidth
            />
            <MagnifyingGlassIcon className="far fa-magnifying-glass" />
            <SlidersButton
              onClick={onOpenSliders}
              variant="light"
              isIconOnly
              color="primary"
              size="sm"
              startContent={<i className={"fal fa-sliders"} />}
              $active={isFilterActive}
            />
            <CharacterDrawerFilters onClose={onCloseSliders} isFiltersVisible={isFiltersVisible} />
          </H1_FlexRow>
          <ConditionalRender condition={false}>
            <H1_FlexRow
              position="relative"
              padding="23px 0px"
              width="100%"
              flex="0 0 50px"
              gap="10px"
            >
              <StyledSwiper
                allowTouchMove={false}
                navigation
                speed={700}
                slidesPerView="auto"
                spaceBetween={10}
                slidesPerGroup={2}
                lazy
              >
                {characterTypes.map((characterType: string, index) => (
                  <SwiperSlide key={characterType + index}>
                    <Box
                      onClick={() => onSelectSection(characterType)}
                      $isColored={
                        filterType?.includes(characterType as CharacterType) ||
                        (characterType === CharacterType.all && !filterType)
                      }
                      width="68px"
                      padding="3px 8px 4px"
                      height="28px"
                      justify="center"
                      align="center"
                    >
                      <H1_TextXs lineHeight="12px" color={theme.gray7}>
                        {characterTypesText[(characterType as CharacterType) || ""]}
                      </H1_TextXs>
                    </Box>
                  </SwiperSlide>
                ))}
              </StyledSwiper>
            </H1_FlexRow>
          </ConditionalRender>
          <H1_FlexColumn flex="1 1 auto" position="relative">
            <DevModePopover
              size={DevModeSize.large}
              placement="left"
              value={{ character_id: selectedCharacter?.id as string }}
              path={"root"}
            />
            <SmallPreviewFlexRow
              $isGestureSelected={isGestureSelected}
              data-auto-id="avatar-drawer-selected-avatar"
              margin="0 0 10px 0"
              position="relative"
              flex="0 0 auto"
              gap="13px"
              justify="center"
              align="center"
              overflow="auto"
            >
              <ConditionalRender condition={!!selectedCharacter?.thumbnails}>
                <BackgroundContainer
                  width="100%"
                  height="182px"
                  justify="center"
                  overflow="hidden"
                  flex="0 0 auto"
                >
                  <CharImage
                    video={selectedCharacter?.thumbnails?.panel.video as string}
                    image={selectedCharacter?.thumbnails?.panel.image as string}
                    disableIcon
                    shouldRestartOnLeave
                  />
                </BackgroundContainer>
              </ConditionalRender>
              <ConditionalRender condition={!selectedCharacter?.thumbnails}>
                <BackgroundContainer
                  width="100%"
                  height="182px"
                  justify="center"
                  overflow="hidden"
                  flex="0 0 auto"
                >
                  <CharImage
                    video={selectedCharacter?.video as string}
                    image={selectedCharacter?.image as string}
                    disableIcon
                    shouldRestartOnLeave
                  />
                </BackgroundContainer>
              </ConditionalRender>
              <SelectedCharacterFogFlexRow
                justify="center"
                align="center"
                position="absolute"
                width="100%"
                height="100%"
              />
              <OpacFlexRow
                padding="10px"
                position="absolute"
                gap="10px"
                align="center"
                onClick={onClickGestures}
              >
                <ColorIcon icon="far fa-hands-clapping" />
                <H1_TextSmall color="white" fontWeight={500}>
                  {intl.formatMessage(characterDrawerMessages.changeGesture)}
                </H1_TextSmall>
              </OpacFlexRow>
              <EmotionFlexRow align="center" position="absolute" gap="5px">
                <ColorIcon color={theme.gray7} icon="far fa-hands-clapping" />
                <H1_TextSmall color={theme.gray7}>
                  {getEmotionEmojiText(intl, selectedCharacter?.emotion)}
                </H1_TextSmall>
              </EmotionFlexRow>
              <IconsFlexRow position="absolute" gap="5px" align="center">
                <H1_FlexRow onClick={onClickFavorite}>
                  <StarIcon
                    size="13px"
                    isCursorPointer
                    icon={selectedCharacter?.favorite ? "fas fa-star" : "far fa-star"}
                    color={theme.gray7}
                  />
                </H1_FlexRow>
              </IconsFlexRow>
            </SmallPreviewFlexRow>
            <ConditionalRender condition={!isGestureSelected}>
              <ApplyToScenes
                isTransparent
                padding="10px"
                element={intl.formatMessage(characterDrawerMessages.avatar)}
              />
              <NoMarginTopDivider />
            </ConditionalRender>
            <CharacterDrawerList
              voiceAssetKey={voiceAssetKey}
              characterAssetKey={characterAssetKey}
              shotType={shotType}
              query={searchValue}
              onClearSearch={() => setSearchValue("")}
            />
          </H1_FlexColumn>
        </H1_FlexColumn>
      </FlexDrawer>
    </H1_FlexColumn>
  );
};

export default CharacterDrawer;
