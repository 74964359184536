import { createSelector } from "reselect";
import { RootState } from "app/store/store";
import { scenesGlobalSelectors } from "app/store/adapters/adapters";
import {
  Draft,
  InternalScene,
  LayoutAsset,
  LayoutCategory,
  Scene,
  SequenceImage,
  SynthesisMarkupLanguage,
  SynthesisMarkupLanguageType
} from "app/types";
import { Voice } from "app/types/voice";
import { getTextOutOfSML, numToMinutesSeconds } from "app/utils/helpers";
import { currentDraft } from "./drafts.selectors";
import { Dictionary } from "@reduxjs/toolkit/src/entities/models";

const currentTemplate = (state: RootState) => state.templates.currentTemplate;
const getCurrentDraft = (state: RootState) => state.drafts.currentDraft;
const workflowResultSml = (state: RootState) =>
  state.drafts.workflowResult?.synthesis_markup_language;
const framePreviewLoadingSelector = (state: RootState) => state.scenes.framePreviewLoading;
const selectedSceneId = (state: RootState) => state.scenes.selectedSceneId;
const selectedEntities = (state: RootState) => state.scenes.entities;
const voicesEntities = (state: RootState) => state.voices.entities;
const scenesTiming = (state: RootState) => state.voices.scenesTiming;

const countWords = (transcript: string): number => {
  // Add spaces around CJK (Chinese, Japanese, and Korean) symbols, so they can be counted as separated words:
  const symbolsChars = "[\u4e00-\u9fff]"; // This is the basic, there are some extensions (more information here: https://unicode-table.com/en/blocks/cjk-unified-ideographs/)
  const clearTranscript = transcript.replace(new RegExp(symbolsChars, "g"), " $& "); // This adds spaces around the CJK characters so they can be counted as separate words.

  // Split transcript into words with various separators:
  const delimiters = [" ", "-", "/", "\\", "\n", "_", "."]; // Possible delimeters that we want to differentiate between words

  const regexPattern = delimiters
    .map((str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")) // Replace special chars (.*+?^${}()\/) warping // - escape special characters in a string so they are treated as literal characters and not special characters in a regular expression.
    .join("|"); // Finally, the array of escaped strings is joined into a single string with the separator "|", which means "or" in regular expression syntax. The resulting regexPattern can be used to split the transcript string into words using the split method and a RegExp object constructed from regexPattern.

  // Spliting clear transcript with regexPattern, filter all Falsy values, Returns the length of the filtered array, which is the number of words in the clearTranscript string.
  return clearTranscript.split(new RegExp(regexPattern)).filter(Boolean).length;
};

export const getAllScenesWordCounting = createSelector(
  [scenesGlobalSelectors.selectAll],
  (allScenes: InternalScene[]): number =>
    allScenes
      .map((scene: InternalScene) =>
        scene.attributes.text?.transcript?.text
          ? countWords(scene.attributes.text?.transcript?.text)
          : 0
      )
      .reduce((sum: number, words) => sum + words, 0)
);

const summerizeNumberOfPauseSeconds = (
  synthesisMarkupLanguage: SynthesisMarkupLanguage[]
): number => {
  return synthesisMarkupLanguage.reduce((sum: number, element) => {
    if (element.type === "pause") {
      return sum + Number(element.time);
    }
    return sum;
  }, 0);
};

export const getVideoEST = createSelector(
  [scenesGlobalSelectors.selectAll, getCurrentDraft, voicesEntities],
  (allScenes: InternalScene[], currentDraft: Draft, voicesEntities) => {
    const pauseAddedTime = allScenes.reduce((prev, cur) => {
      const synthesisMarkupLanguage =
        cur.attributes.text?.transcript?.synthesis_markup_language?.nodes;
      if (synthesisMarkupLanguage) {
        return prev + summerizeNumberOfPauseSeconds(synthesisMarkupLanguage);
      }
      return prev;
    }, 0);

    const countScenesWords = allScenes.reduce((prev, cur) => {
      const synthesisMarkupLanguage =
        cur.attributes.text?.transcript?.synthesis_markup_language?.nodes;
      const count = synthesisMarkupLanguage
        ? countWords(getTextOutOfSML(synthesisMarkupLanguage))
        : countWords(cur.attributes.text?.transcript?.text as string);

      const sceneVoice = getVoiceForSceneHelper(currentDraft, cur, voicesEntities);

      const wordsPerMin = sceneVoice?.wpm ?? 170;
      return prev + count / wordsPerMin;
    }, 0);

    const timeText = numToMinutesSeconds(countScenesWords + pauseAddedTime / 60);
    return timeText;
  }
);

export const getSceneEST = createSelector(
  [
    scenesGlobalSelectors.selectAll,
    (state: RootState, { sceneId }) => ({ sceneId }),
    (state: RootState, { sceneId }) => getSceneVoiceBySceneId(state, sceneId)
  ],
  (allScenes, { sceneId }, sceneVoice) => {
    let text = "";
    let pauseAddedTime = 0;
    const currentScene = allScenes.find((s) => s.id === sceneId) as InternalScene;
    if (!currentScene) {
      return "";
    }

    const wordsPerMin = sceneVoice?.wpm ?? 170;
    const synthesisMarkupLanguage =
      currentScene.attributes.text?.transcript?.synthesis_markup_language?.nodes;
    if (sceneId && synthesisMarkupLanguage) {
      pauseAddedTime = summerizeNumberOfPauseSeconds(synthesisMarkupLanguage);
      text = getTextOutOfSML(synthesisMarkupLanguage);
    } else {
      text = currentScene.attributes.text?.transcript?.text as string;
    }
    const words = countWords(text);
    const timeText = numToMinutesSeconds(words / wordsPerMin + pauseAddedTime / 60);
    return timeText;
  }
);

interface ScenesMedia {
  [key: string]: string[];
}

export const getMediaPerScene = createSelector([scenesGlobalSelectors.selectAll], (scenes) => {
  return scenes.reduce<ScenesMedia>((acc, cur) => {
    acc[cur.id] = (cur.layout.assets.media || [])
      .map((media) => media.key)
      .map((key) => {
        return cur.attributes?.media?.[key]?.url;
      })
      .filter((value) => !!value) as string[];
    return acc;
  }, {});
});
export const getAllScenesMediaValues = createSelector([getMediaPerScene], (scenesMedia) => {
  return Object.values(scenesMedia).flat();
});

export const getTextsAssetFromLayoutWithoutTranscript = createSelector(
  [
    scenesGlobalSelectors.selectEntities,
    (state: RootState, sceneId: string, onlyNoneCards = false) => ({ sceneId, onlyNoneCards })
  ],
  (scenes, { sceneId, onlyNoneCards }) => {
    const scene = scenes[sceneId];
    if (scene && scene.layout) {
      return (
        scene.layout?.assets?.text?.filter(
          (asset: LayoutAsset) =>
            asset.key !== "transcript" && (onlyNoneCards ? !asset.card_id : true)
        ) || []
      );
    }
    return [];
  }
);

export const getSceneLayout = createSelector(
  [(state: RootState, sceneId: string) => scenesGlobalSelectors.selectById(state, sceneId)],
  (scene) => {
    return scene?.layout;
  }
);

export const getSceneLayoutAsset = createSelector(
  [
    (state: RootState, sceneId: string, attributeType: LayoutCategory, assetId: string) => ({
      scene: scenesGlobalSelectors.selectById(state, sceneId),
      attributeType,
      assetId
    })
  ],
  ({ scene, attributeType, assetId }) => {
    return (scene?.layout?.assets[attributeType] as LayoutAsset[])?.find(
      (theMedia) => theMedia.key === assetId
    );
  }
);

export const getSceneLayoutBySelectedSceneId = createSelector(
  [selectedSceneId, selectedEntities],
  (sceneId, entities) => {
    return entities[sceneId as string]?.layout;
  }
);

export const getScenePreviewLoading = createSelector(
  [framePreviewLoadingSelector, (state: RootState, sceneId: string) => sceneId],
  (framePreviewLoading, sceneId) => {
    return framePreviewLoading[sceneId] || {};
  }
);

export const getScenesFramePreviewTiming = createSelector(
  [scenesGlobalSelectors.selectAll, scenesTiming, (state: RootState, FPS: number) => FPS],
  (scenes, timing, FPS): SequenceImage[] => {
    return scenes
      .filter((scene) => !scene.skip)
      .map((scene, index) => {
        const time = timing[index];
        const from = index == 0 ? 0 : timing[index - 1]?.[1] || 0;
        return {
          imageUrl: scene.last_preview_url as string,
          durationInFrames: FPS * (time?.[1] - time?.[0] || 0),
          from: from * FPS
        };
      });
  }
);

export const isSkipDisabled = createSelector(
  [scenesGlobalSelectors.selectAll, (state, sceneId: string) => sceneId],
  (scenes, sceneId) => {
    const scene = scenes.find((scene: InternalScene) => scene.id === sceneId);
    return scenes.filter((item) => !item.skip).length === 1 && !scene?.skip;
  }
);

export const getScenesTotalSmlWorkflow = createSelector(
  [scenesGlobalSelectors.selectAll, workflowResultSml],
  (scenes, workflowResultSml) => {
    const smlByScenes = (() => {
      const result = scenes.reduce((acc, scene) => {
        const sml = scene?.attributes?.text?.transcript?.synthesis_markup_language?.nodes || [];
        if (sml.length) {
          return acc
            .concat(sml)
            .concat([{ type: SynthesisMarkupLanguageType.text, text: "\n", version: 1 }]);
        } else {
          return acc;
        }
      }, [] as SynthesisMarkupLanguage[]);
      return result;
    })();
    const synthesis_markup_language = workflowResultSml || smlByScenes || [];
    return synthesis_markup_language;
  }
);

export const getTotalNumberOfScenes = createSelector(
  [scenesGlobalSelectors.selectAll],
  (scenes) => {
    return scenes.length;
  }
);

export const getAssetsByCardIdAndCategory = createSelector(
  [
    scenesGlobalSelectors.selectAll,
    selectedSceneId,
    (state, cardId: string, layoutCategory: LayoutCategory) => ({ cardId, layoutCategory })
  ],
  (scenes, selectedSceneId, { cardId, layoutCategory }) => {
    const currentScene = scenes.find((scene) => scene.id === selectedSceneId) as Scene;
    const { assets } = currentScene.layout;
    const filteredAssets = assets[layoutCategory]?.filter((elem) => elem.card_id === cardId);

    return filteredAssets || [];
  }
);

function getVoiceForSceneHelper(
  draft: Draft,
  scene: Scene,
  voices: Dictionary<Voice>
): Voice | undefined {
  if (draft.global_character) {
    const voice = voices[draft.attributes?.voice?.voice?.voice_id as string];
    return voice;
  } else {
    const layout = scene.layout;
    //     first find who speaks
    const presetSpeakKeyTaking = layout.assets.character?.find(
      (asset) => !!asset?.preset?.is_talking
    )?.key;
    const restrictedVoicePerCharacter =
      layout.assets.character?.reduce((acc, asset) => {
        if (asset?.restrictions?.voice_key) {
          acc[asset.key] = asset.restrictions.voice_key;
        }
        return acc;
      }, {} as { [key: string]: string }) || {};

    const presetOverrideKeyTaking = scene.attributes.character
      ? Object.entries(scene.attributes.character).find(
          ([, value]) => !!value?.preset_override?.is_talking
        )?.[0]
      : undefined;

    if (restrictedVoicePerCharacter[(presetOverrideKeyTaking || presetSpeakKeyTaking) as string]) {
      const voiceId: string | undefined =
        scene.attributes?.voice?.[
          restrictedVoicePerCharacter[
            (presetOverrideKeyTaking || presetSpeakKeyTaking) as string
          ] as string
        ]?.voice_id;

      return (
        voices[voiceId as string] || voices[draft.attributes?.voice?.voice?.voice_id as string]
      );
    } else {
      return (
        voices[scene.attributes?.voice?.voice?.voice_id as string] ||
        voices[draft.attributes?.voice?.voice?.voice_id as string]
      );
    }
  }
}

export const getSceneVoiceBySceneId = createSelector(
  [
    currentDraft,
    currentTemplate,
    scenesGlobalSelectors.selectEntities,
    voicesEntities,
    (state, sceneId: string) => sceneId
  ],
  (draft, template, scenes, voices, sceneId) => {
    const scene: Scene = scenes[sceneId] as Scene;
    if (!scene) {
      return undefined;
    }
    return getVoiceForSceneHelper(draft, scene, voices);
  }
);

export default {
  getMediaPerScene,
  getAllScenesMediaValues,
  getTextsAssetFromLayoutWithoutTranscript,
  getSceneLayout,
  getScenesFramePreviewTiming,
  getScenesTotalSmlWorkflow,
  getTotalNumberOfScenes,
  getSceneVoiceBySceneId
};
