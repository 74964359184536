/* eslint-disable camelcase */

import { PlanEnum } from "app/types/userData";

import { CharacterType } from "app/types/character";

export type AssetGroup = "Video" | "Intro" | "Logo" | "Style";
export type TemplateAssetType = "media" | "color" | "text" | "music" | "character" | "voice";

export interface AssetRestrictions {
  required?: boolean;
  media_type?: string;
}
export interface TemplateAssetProperty {
  key: string;
  title: string;
  type: TemplateAssetType;
  restrictions: AssetRestrictions;
  group: AssetGroup;
}

export interface TemplateAsset {
  title: string;
  group: AssetGroup;
  restrictions: AssetRestrictions;
  type: TemplateAssetType;
  properties: TemplateAssetProperty[];
  key: string;
  hidden: boolean;
}

export const INTRO_OUTRO_KEYS = ["url_intro", "url_outro"];

export interface TemplateAssets {
  [key: string]: TemplateAsset;
}
export interface Template {
  id: string;
  available: boolean;
  title: string;
  description: string;
  assets: TemplateAssets;
  flavor: string;
  image: string;
  video: string;
  is_public: boolean;
  layouts: string[];
  pro: boolean;
  plans: PlanEnum[];
  characters: string[];
  character_types: CharacterType[];
  voices: string[];
  render_config?: {
    width: number;
    height: number;
  };
}

export interface TemplateList {
  [key: string]: Template[];
}
// todo temp
export interface TemplateAssetWithId extends TemplateAsset {
  key: string;
}

export enum SupportedGroups {
  Video = "Video",
  Intro = "Intro",
  Logo = "Logo",
  Style = "Style",
  Music = "music"
}

export enum PaletteColorKey {
  "color_W" = "color_W",
  "color_B" = "color_B",
  "color_A1" = "color_A1",
  "color_A2" = "color_A2",
  "color_A3" = "color_A3"
}

export interface PaletteColor {
  key: PaletteColorKey;
  color: string;
}

export enum PaletteTypes {
  Custom = "custom",
  Default = "pre_maid",
  Brand = "brand"
}

export interface Palette {
  id?: string;
  name: string;
  colors: PaletteColor[];
  custom?: boolean;
}

export enum TemplateSelection {
  LnD = "LnD",
  Sales = "Sales",
  Product = "Product"
}
