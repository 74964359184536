import React, { useEffect, useMemo, useState } from "react";
import useModal, { ModalName } from "app/hooks/useModal";
import { useIntl } from "react-intl";
import {
  H1_TextMidHeadline,
  H1_TextSmall,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import { importDocumentsModalMessages } from "app/components/editor/documents/messages";
import StartFromTemplate from "app/components/editor/documents/StartFromTemplate";

import { Location, MimeType, PlanEnum, PresentationTextType } from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import ConditionalRender from "app/components/common/ConditionalRender";

import { fetchingStatus } from "app/utils/helpers";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled, { useTheme } from "styled-components";
import UsePresentationToDraft from "app/components/editor/presentations/usePresentationToDraft";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import * as Sentry from "@sentry/react";
import LimitSlidesWarningModal from "app/components/editor/presentations/LimitSlidesWarningModal";
import UploadPresentationContent from "app/components/editor/presentations/UploadPresentationContent";
import useUpgradeModal from "app/hooks/useUpgradeModal";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { MenuProps, Typography } from "antd";
import { H1_Dropdown } from "app/components/_Infrastructure/design-system/dropdown";
import { draftsActions } from "app/store/slices/drafts.slice";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import * as draftsSelectors from "app/store/selectorsV2/drafts.selectors";
import { useNavigate } from "react-router-dom";
import PresentationLoadingCreationModal from "app/components/editor/documents/PresentationLoadingCreationModal";
import DocumentLoadingCreationModal from "app/components/editor/documents/DocumentLoadingCreationModal";
import { Button } from "@nextui-org/react";
import { getLimits } from "app/store/selectorsV2/workspaces.selectors";

const planMaxSlides = {
  [PlanEnum.legacy]: 6,
  [PlanEnum.onBoarding]: 6,
  [PlanEnum.basic]: 6,
  [PlanEnum.personal]: 10,
  [PlanEnum.enterprise]: 15,
  [PlanEnum.business]: 15,
  [PlanEnum.advanced]: 15,
  [PlanEnum.pro]: 15
};

const getNextAllowedPlan = (
  plan: PlanEnum,
  totalSlides: number,
  MAX_SLIDES: number
): PlanEnum | undefined => {
  if (totalSlides <= MAX_SLIDES) {
    return undefined;
  }

  const result = Object.entries(planMaxSlides).find(([, value]) => value >= totalSlides);

  if (result) {
    return result[0] as PlanEnum;
  }
  return PlanEnum.business;
};

const Blue4Circle = styled(H1_FlexRow)`
  border-radius: 50%;
  display: inline-flex;
  i {
    color: ${({ theme }) => theme.gray1};
  }
  background-color: ${({ theme }) => theme.blue4};
`;

const NameText = styled(Typography.Text)`
  color: ${({ theme }) => theme.gray11};
  font-size: 14px;
  line-height: 22px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
`;

const PresentationNameFlexRow = styled(H1_FlexRow)`
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
`;

const StyledDropdown = styled(H1_Dropdown)`
  border: none;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  color: ${(props) => props.theme.gray11};
  background-color: ${({ theme }) => theme.gray1};
  transition: background-color 0.3s ease-in-out;
  width: 274px;
  &:hover {
    background-color: ${({ theme }) => theme.gray2};
    color: ${(props) => props.theme.gray11};
  }
  padding: 10px;
  &.text-type {
    .ant-dropdown {
      width: auto;
      .ant-dropdown-menu {
        padding: 10px;
        li.clickable:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
    max-height: 180px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.gray4};
    .clickable {
      border-radius: 5px;
      border: 1px solid ${({ theme }) => theme.gray4};
    }
    .selected {
      border: 1px solid ${({ theme }) => theme.blue4};
    }
    font-size: 12px;
    .ant-dropdown-menu-item {
      padding: 5px 10px;
      i {
        color: ${({ theme }) => theme.gray1};
      }
      &:hover {
        background-color: ${({ theme }) => theme.gray3};
      }
    }
    .ant-dropdown-menu-item-group-title {
      padding: 5px 10px;
    }
  }
`;

const UploadWrapper = styled(H1_FlexColumn)`
  > div {
    height: 100%;
    > span {
      height: 100%;
    }
  }
`;

const ChangeButton = styled(Button)`
  && {
    background-color: transparent;
  }
  &:hover {
    text-decoration: underline;
  }
`;
const StartFromPresentationModal = () => {
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [openDocumentCreationModal, setOpenDocumentCreationModal] = useState(false);
  const [openPresentationCreationModal, setOpenPresentationCreationModal] = useState(false);

  const [currentStep, setCurrentStep] = useState(0);
  const { currentPlan } = useCurrentPlan();
  const intl = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();

  const { closeModal, editorModalOpen } = useModal();
  const showPaymentModal = useUpgradeModal();
  const dispatch = useAppDispatch();
  const {
    file,
    reset,
    onSelectFile,
    importLoading,
    executeImport,
    parsedImages,
    createPresentation,
    setTextType,
    textType,
    error: executeError
  } = UsePresentationToDraft();

  const limits = useAppSelector(getLimits);

  const MAX_SLIDES = limits.scenes;
  const targetPlan = getNextAllowedPlan(currentPlan as PlanEnum, parsedImages.length, MAX_SLIDES);

  const visible = editorModalOpen?.open === ModalName.presentationUploader;
  const createDraftStatus = useAppSelector((state) => state.drafts.createDraftStatus);
  const createdDraft = useAppSelector(draftsSelectors.currentDraft);
  const draftGeneratorOrderId = useAppSelector((state) => state.drafts.createDraftGeneratorOrderId);

  const createDraftFromImportLoading = createDraftStatus === fetchingStatus.loading;
  const startFromDocumentStepsTitles = [
    {
      title: intl.formatMessage(importDocumentsModalMessages.FirstStepPresentation)
    },
    {
      title: intl.formatMessage(importDocumentsModalMessages.SecondStepPresentation)
    }
  ];

  const textTypeTitle: string = useMemo(() => {
    switch (textType) {
      case PresentationTextType.Notes:
        return intl.formatMessage(importDocumentsModalMessages.Notes);
      case PresentationTextType.Slides:
        return intl.formatMessage(importDocumentsModalMessages.Slides);
      default:
        return intl.formatMessage(importDocumentsModalMessages.Notes);
    }
  }, [textType]);

  const textTypeIcon: string = useMemo(() => {
    switch (textType) {
      case PresentationTextType.Notes:
        return "far fa-note-sticky";
      case PresentationTextType.Slides:
        return "far fa-text";
      default:
        return "far fa-note-sticky";
    }
  }, [textType]);

  useEffect(() => {
    if (createDraftStatus === fetchingStatus.idle || !visible) {
      return;
    }
    if (createDraftStatus === fetchingStatus.succeeded) {
      const editorDraftLink = `${Location.Editor}/${createdDraft.id}`;
      dispatch(analyticsEvents.StartFromPresentation({ status: "success" }));
      dispatch(analyticsEvents.navigateToEditor({ source: "presentation" }));
      dispatch(draftsActions.setCreateDraftStatusToIdle());
      navigate(editorDraftLink);
      setOpenPresentationCreationModal(false);
      setOpenDocumentCreationModal(false);
      closeModal();
    } else if (createDraftStatus === fetchingStatus.failed) {
      dispatch(analyticsEvents.StartFromPresentation({ status: "fail" }));
      dispatch(draftsActions.setCreateDraftStatusToIdle());
      setOpenDocumentCreationModal(false);

      closeModal();
    }
  }, [createDraftStatus, visible]);

  useEffect(() => {
    if (parsedImages.length > MAX_SLIDES) {
      setShowWarningModal(true);
      setOpenPresentationCreationModal(false);
    } else if (parsedImages.length) {
      createPresentation(textType);
    }
  }, [parsedImages]);
  useEffect(() => {
    if (executeError && visible) {
      setOpenDocumentCreationModal(false);
      setOpenPresentationCreationModal(false);
      closeModal();
    }
  }, [executeError]);

  useEffect(() => {
    if (!visible) {
      setCurrentStep(0);
      reset();
      setOpenPresentationCreationModal(false);
    }
  }, [visible]);

  const stepTwoSucceeded = () => {
    try {
      executeImport();
      setOpenPresentationCreationModal(true);
    } catch (err) {
      setOpenDocumentCreationModal(false);
      setOpenPresentationCreationModal(false);

      console.error("error", err);
      Sentry.captureException(err, {
        extra: { description: "user failed import file" }
      });
    }
  };
  const onWarningContinue = () => {
    setShowWarningModal(false);
    setOpenPresentationCreationModal(false);
    setOpenDocumentCreationModal(true);
    createPresentation(textType);
  };

  const onWarningBack = () => {
    setShowWarningModal(false);
  };

  const onUpgrade = () => {
    setShowWarningModal(false);
    showPaymentModal({
      source: "upgrade_presentation",
      requestedPlans: [targetPlan as PlanEnum]
    });
  };

  const setFileSelected = (selectedFile: File) => {
    if (onSelectFile(selectedFile)) {
      setCurrentStep(1);
    }
  };

  const onChangeFile = () => {
    setCurrentStep(0);
  };

  const onSelectSlides = () => {
    setTextType(PresentationTextType.Slides);
  };

  const onSelectNotes = () => {
    setTextType(PresentationTextType.Notes);
  };

  const textTypeItems: MenuProps["items"] = useMemo(() => {
    return [
      {
        className: `playback-menu clickable ${
          textType === PresentationTextType.Notes ? "selected" : ""
        }`,
        key: "notes",
        icon: (
          <Blue4Circle justify="center" align="center" width="20px" height="20px">
            <H1_Icon icon="far fa-note-sticky" color={theme.gray1} />
          </Blue4Circle>
        ),
        onClick: onSelectNotes,
        label: <H1_TextXs>{intl.formatMessage(importDocumentsModalMessages.Notes)}</H1_TextXs>
      },
      {
        className: `playback-menu clickable ${
          textType === PresentationTextType.Slides ? "selected" : ""
        }`,
        key: "slides",
        icon: (
          <Blue4Circle justify="center" align="center" width="20px" height="20px">
            <H1_Icon icon="far fa-text" color={theme.gray1} />
          </Blue4Circle>
        ),
        onClick: onSelectSlides,
        label: <H1_TextXs>{intl.formatMessage(importDocumentsModalMessages.Slides)}</H1_TextXs>
      }
    ];
  }, [textType]);
  if (visible && openDocumentCreationModal) {
    return (
      <DocumentLoadingCreationModal trayKey={draftGeneratorOrderId} startFromType="presentation" />
    );
  }
  if (visible && openPresentationCreationModal) {
    return <PresentationLoadingCreationModal trayKey={draftGeneratorOrderId} />;
  }
  return (
    <>
      <ConditionalRender condition={showWarningModal}>
        <LimitSlidesWarningModal
          onContinue={onWarningContinue}
          onBack={onWarningBack}
          loading={importLoading}
          maxLimit={MAX_SLIDES}
          onUpgrade={onUpgrade}
          showUpgradeButton={!!targetPlan}
        />
      </ConditionalRender>
      <ConditionalRender condition={!showWarningModal}>
        <StartFromTemplate
          open={visible}
          current={currentStep}
          onClose={closeModal}
          displaySteps={!createDraftFromImportLoading}
          titles={
            <H1_TextMidHeadline color={theme.gray11} whiteSpace="normal">
              {intl.formatMessage(importDocumentsModalMessages.PresentationHeadlineTitle)}
            </H1_TextMidHeadline>
          }
          startFromSteps={startFromDocumentStepsTitles}
        >
          <ConditionalRender condition={!createDraftFromImportLoading}>
            <ConditionalRender condition={currentStep === 0}>
              <UploadWrapper height="100%" width="100%">
                <UploadPresentationContent
                  file={file}
                  fileTypes={MimeType.presentations}
                  onSelectFile={setFileSelected}
                />
              </UploadWrapper>
            </ConditionalRender>
            <ConditionalRender condition={currentStep === 1}>
              <H1_FlexColumn flex="1" alignSelf="flex-start">
                <H1_FlexRow gap="14px" align="center" margin="0 0 30px 0">
                  <PresentationNameFlexRow
                    height="44px"
                    width="274px"
                    padding="11px 10px"
                    justify="space-between"
                    align="center"
                  >
                    <H1_FlexRow gap="7px" align="center">
                      <img
                        height="19px"
                        width="20px"
                        src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/ppicon.png"
                        alt="pp"
                      />
                      <NameText ellipsis={{ tooltip: file?.name }}>{file?.name}</NameText>
                    </H1_FlexRow>
                    <Button
                      variant="light"
                      isIconOnly
                      size="sm"
                      startContent={<i className="far fa-xmark" />}
                      onClick={onChangeFile}
                      isLoading={createDraftFromImportLoading}
                    />
                  </PresentationNameFlexRow>
                  <ChangeButton variant="light" color="primary" onClick={onChangeFile}>
                    {intl.formatMessage(importDocumentsModalMessages.ChangeButton)}
                  </ChangeButton>
                </H1_FlexRow>
                <H1_TextSmall lineHeight="14px" margin="0 0 17px 0">
                  {intl.formatMessage(importDocumentsModalMessages.CreateScriptFrom)}
                </H1_TextSmall>
                <StyledDropdown
                  icon={
                    <Blue4Circle justify="center" align="center" width="20px" height="20px">
                      <H1_Icon icon={textTypeIcon} color={theme.gray1} />
                    </Blue4Circle>
                  }
                  title={textTypeTitle}
                  destroyPopupOnHide
                  trigger={["click"]}
                  menu={{ items: textTypeItems }}
                  className="text-type"
                  getPopupContainer={() => document.querySelector(`.text-type`) as HTMLElement}
                  withChevron
                >
                  <H1_Icon icon="fa-regular fa-chevron-down" size="22px" />
                </StyledDropdown>
              </H1_FlexColumn>
              <H1_FlexRow justify="flex-end" width="100%">
                <Button color="primary" onClick={stepTwoSucceeded}>
                  {intl.formatMessage(importDocumentsModalMessages.CreateVideoButton)}
                </Button>
              </H1_FlexRow>
            </ConditionalRender>
          </ConditionalRender>
        </StartFromTemplate>
      </ConditionalRender>
    </>
  );
};
export default StartFromPresentationModal;
