import { createSelector } from "reselect";
import { RootState } from "app/store/store";
import {
  avatarsGlobalSelectors,
  charactersGlobalSelectors,
  scenesGlobalSelectors
} from "app/store/adapters/adapters";
import { Character, CharacterAll, CharacterType, Emotion } from "app/types/character";
import { currentWorkspacePlan } from "app/store/selectorsV2/workspaces.selectors";
import { capitalize } from "lodash-es";
import { Gender } from "app/types";

export const createDraftStatus = (state: RootState) => state.drafts.createDraftStatus;
export const appData = (state: RootState) => state.user.appData;
export const template = (state: RootState) => state.templates.currentTemplate;
export const avatars = (state: RootState) => avatarsGlobalSelectors.selectAll(state);

const selectedEmotions = (state: RootState) => state.characters.selectedEmotions;
const gestureSourceId = (state: RootState) => state.characters.gestureSourceId;
const filters = (state: RootState) => state.characters.filters;
const getCurrentDraft = (state: RootState) => state.drafts.currentDraft;
const currentSelectedSceneID = (state: RootState) => state.scenes.selectedSceneId;

export const getAvatarsInProgress = createSelector(
  [avatarsGlobalSelectors.selectAll],
  (avatars) => {
    return avatars.filter((avatar) => avatar.status === "in_progress");
  }
);
export const getDrawerCharacterBySelectedScene = createSelector(
  [
    currentSelectedSceneID,
    charactersGlobalSelectors.selectAll,
    scenesGlobalSelectors.selectEntities,
    getCurrentDraft,
    (state, characterAssetKey: string) => characterAssetKey
  ],
  (sceneId, allCharacters, scenes, currentDraft, characterAssetKey) => {
    const scene = scenes[sceneId as string];
    if (sceneId && scene) {
      const charId = currentDraft.global_character
        ? currentDraft.attributes?.character?.[characterAssetKey]?.character_id
        : scene.attributes?.character?.[characterAssetKey]?.character_id ||
          currentDraft.attributes?.character?.[characterAssetKey]?.character_id;

      return allCharacters.find(
        (character: { character_id: string }) => character?.character_id === charId
      );
    }

    return null;
  }
);

export const filteredTemplateCharacters = createSelector(
  [charactersGlobalSelectors.selectAll, template],
  (allCharacters, currentTemplate) => {
    if (currentTemplate?.characters?.length) {
      return allCharacters.filter((c: Character) =>
        currentTemplate?.characters?.includes(c.character_id)
      );
    }
    return currentTemplate ? allCharacters : [];
  }
);

const isPro = (plans?: string[], plan?: string): boolean => {
  return !!plans?.length && !plans.includes(String(plan));
};

export const getPublicCharacters = createSelector(
  [filteredTemplateCharacters, currentWorkspacePlan],
  (allCharacters, plan) => {
    return allCharacters
      .filter((c: Character) => !c.tailor_made)
      .map((character) => {
        return {
          ...character,
          pro: isPro(character.plans, plan)
        };
      });
  }
);

export const getEmotionsBucketList = createSelector(
  [charactersGlobalSelectors.selectAll],
  (allCharacters) => {
    const defaultEmotion = Emotion.Neutral;
    return allCharacters.reduce((acc: Record<string, Emotion | undefined>, curr) => {
      if (!acc[curr.sourceId]) {
        acc[curr.sourceId] = curr.emotion;
      }
      if (curr.emotion === defaultEmotion) {
        acc[curr.sourceId] = curr.emotion;
      }

      return acc;
    }, {});
  }
);

const getCharactersBySourceId = (allCharacters: Character[], gestureSourceId: string) => {
  return allCharacters.filter((character: Character) => character.sourceId === gestureSourceId);
};

const getDistinctCharacters = (
  allCharacters: Character[],
  selectedEmotions: Record<string, Emotion>,
  emotionsBucketList: Record<string, Emotion | undefined>
) => {
  return allCharacters.filter((character: Character) => {
    const selectedEmotion = selectedEmotions[character.sourceId];
    if (selectedEmotion && character.emotion) {
      return selectedEmotion === character.emotion;
    }
    if (
      emotionsBucketList[character.sourceId] === undefined ||
      emotionsBucketList[character.sourceId] === character.emotion
    ) {
      return true;
    }

    return false;
  });
};

export const countCharactersBySourceId = createSelector(
  [charactersGlobalSelectors.selectAll, (state, sourceId) => sourceId],
  (allCharacters, sourceId) =>
    allCharacters.filter((character: Character) => character.sourceId === sourceId).length
);

export const getFilteredCharactersByProperties = createSelector(
  [
    charactersGlobalSelectors.selectAll,
    (state) => state.characters.ids,
    filteredTemplateCharacters,
    template,
    currentWorkspacePlan,
    filters,
    getEmotionsBucketList,
    selectedEmotions,
    gestureSourceId,
    (state, searchQuery: string, byCurrentTemplate: boolean, shotType?: string) => ({
      searchQuery: searchQuery || "",
      byCurrentTemplate: byCurrentTemplate || false,
      shotType
    })
  ],
  (
    allCharacters,
    ids,
    filteredTemplateCharacters,
    currentTemplate,
    plan,
    filters,
    emotionsBucketList,
    selectedEmotions,
    gestureSourceId,
    { searchQuery, byCurrentTemplate, shotType }
  ) => {
    let filteredCharacters = gestureSourceId
      ? getCharactersBySourceId(
          currentTemplate ? filteredTemplateCharacters : allCharacters,
          gestureSourceId
        )
      : getDistinctCharacters(
          currentTemplate ? filteredTemplateCharacters : allCharacters,
          selectedEmotions,
          emotionsBucketList
        );
    if (byCurrentTemplate) {
      filteredCharacters = filteredCharacters.filter((character) => {
        const isTemplateSupported =
          currentTemplate?.character_types && character.type
            ? currentTemplate?.character_types?.includes(character.type)
            : true;
        return isTemplateSupported && (shotType ? character.shot_type === shotType : true);
      });
    }

    if (filters?.gender && filters?.gender !== Gender.all) {
      filteredCharacters = filteredCharacters.filter((c: Character) => c.gender === filters.gender);
    }
    if (filters?.age && filters?.age !== CharacterAll) {
      filteredCharacters = filteredCharacters.filter((c: Character) => c.age_range === filters.age);
    }
    if (filters?.hairStyle && filters?.hairStyle !== CharacterAll) {
      filteredCharacters = filteredCharacters.filter(
        (c: Character) => c.hair_style?.toLowerCase() === filters.hairStyle?.toLowerCase()
      );
    }
    if (filters?.clothing && filters?.clothing !== CharacterAll) {
      filteredCharacters = filteredCharacters.filter(
        (c: Character) => c.clothing_style?.toLowerCase() === filters.clothing?.toLowerCase()
      );
    }
    if (filters?.myAvatars === false && filters?.publicAvatars) {
      filteredCharacters = filteredCharacters.filter((c: Character) => !c.tailor_made);
    }
    if (filters?.myAvatars && filters?.publicAvatars === false) {
      filteredCharacters = filteredCharacters.filter((c: Character) => c.tailor_made);
    }
    if (filters?.type) {
      filteredCharacters = filteredCharacters.filter(
        (c: Character) =>
          filters.type?.includes(CharacterType.all) ||
          filters.type?.includes(c.type?.toLowerCase() as CharacterType)
      );
    } else {
      // TODO: uncomment when time can be relative to user's timezone
      // const now = new Date();
      // const halfHourAgo = new Date(now.getTime() - 30 * 60 * 1000);
      filteredCharacters = filteredCharacters.reduce((acc: Character[], item: Character) => {
        // if (item.type?.toLowerCase() === "webcam" && new Date(item.created_at) < halfHourAgo) {
        if (item.type?.toLowerCase() === "webcam") {
          acc.unshift(item); // Add to the beginning
        } else {
          acc.push(item); // Add to the end
        }
        return acc;
      }, []);
    }

    filteredCharacters = filteredCharacters
      .filter(
        (c: Character) =>
          c.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          c.age_range?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          c.clothing_style?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          c.gender?.toLowerCase() === searchQuery.toLowerCase()
      )
      .map((character) => {
        return {
          ...character,
          pro: isPro(character.plans, plan)
        };
      });
    if (filters?.free && !filters?.pro) {
      filteredCharacters = filteredCharacters.filter((character) => !character.pro);
    }

    if (!filters?.free && filters?.pro) {
      filteredCharacters = filteredCharacters.filter((character) => character.pro);
    }

    return filteredCharacters;
  }
);

export const getUniqueDropdownValuesByProperties = createSelector(
  [
    charactersGlobalSelectors.selectAll,
    (state, properties: string[], allText: string) => ({
      properties,
      allText
    })
  ],
  (allCharacters, { properties, allText }) => {
    const result: any = {};
    properties.forEach((property) => {
      result[property] = [
        ...new Set(allCharacters.map((item) => item[property as keyof Character]))
      ]
        .filter((item) => !!item)
        .sort((a, b) => (a as string).localeCompare(b as string))
        .map((item) => ({
          value: item,
          label: capitalize(item as string)
        }));
      result[property].unshift({ value: CharacterAll, label: allText });
    });

    return result;
  }
);

export const getUniqueDropdownValuesByPropertiesNextUI = createSelector(
  [
    charactersGlobalSelectors.selectAll,
    (state, properties: string[], allText: string) => ({
      properties,
      allText
    })
  ],
  (allCharacters, { properties, allText }) => {
    const result: any = {};
    properties.forEach((property) => {
      result[property] = [
        ...new Set(allCharacters.map((item) => item[property as keyof Character]))
      ]
        .filter((item) => !!item)
        .sort((a, b) => (a as string).localeCompare(b as string))
        .map((item) => ({
          key: item,
          label: capitalize(item as string)
        }));
      result[property].unshift({ key: CharacterAll, label: allText });
    });

    return result;
  }
);

export const getPublicCharactersByTag = createSelector(
  [charactersGlobalSelectors.selectAll, (state, tag) => ({ tag })],
  (allCharacters, { tag }) => {
    return allCharacters.filter((character) => character.tags?.includes(tag));
  }
);

export const SlicePublicCharacterSize = createSelector(
  [(state, numOfCharacters, publicCharacters) => ({ numOfCharacters, publicCharacters })],
  ({ publicCharacters, numOfCharacters }) => {
    return numOfCharacters ? publicCharacters.slice(0, numOfCharacters) : publicCharacters;
  }
);

export const getFilteredCharacters = createSelector(
  [
    charactersGlobalSelectors.selectAll,
    (state, { searchQuery }: { searchQuery?: string }) => ({ searchQuery: searchQuery || "" })
  ],
  (allCharacters, { searchQuery }) => {
    return allCharacters.filter(
      (c: Character) =>
        c.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        c.gender?.toLowerCase() === searchQuery.toLowerCase()
    );
  }
);

export const getTailorMadeCharacters = createSelector(
  [filteredTemplateCharacters, appData],
  (allCharacters) => allCharacters.filter((c: Character) => !!c.tailor_made)
);

export const getMobileCharacters = createSelector(
  [filteredTemplateCharacters, currentWorkspacePlan],
  (allCharacters, currentAppPlan) => {
    return allCharacters
      .filter((c: Character) => c.type === CharacterType.mobile)
      .map((character) => {
        return {
          ...character,
          pro: isPro(character.plans, currentAppPlan)
        };
      });
  }
);

export const getCharactersBucketByOutfitAndSource = createSelector(
  [charactersGlobalSelectors.selectAll, currentWorkspacePlan],
  (allCharacters, currentAppPlan) => {
    return allCharacters
      .filter(
        (character: Character) => !!character.character_source && !!character.character_outfit
      )
      .map((character) => ({
        ...character,
        pro: isPro(character.plans, currentAppPlan)
      }))
      .reduce((acc: Record<string, Character[]>, curr: Character) => {
        if (!acc[curr.sourceId]) {
          acc[curr.sourceId] = [];
        }

        acc[curr.sourceId].push(curr);

        return acc;
      }, {});
  }
);

export const getAllMobileCharacters = createSelector(
  [charactersGlobalSelectors.selectAll, currentWorkspacePlan],
  (allCharacters, currentAppPlan) => {
    return allCharacters
      .filter((c: Character) => c.type === CharacterType.mobile)
      .map((character) => {
        return {
          ...character,
          pro: isPro(character.plans, currentAppPlan)
        };
      });
  }
);

export const getAllWebcamCharacters = createSelector(
  [charactersGlobalSelectors.selectAll, currentWorkspacePlan],
  (allCharacters, currentAppPlan) => {
    return allCharacters
      .filter((c: Character) => c.type === CharacterType.webcam)
      .map((character) => {
        return {
          ...character,
          pro: isPro(character.plans, currentAppPlan)
        };
      });
  }
);
