/* eslint-disable camelcase */
import { Gender } from "app/types/voice";
import { PlanEnum } from "app/types/userData";

export interface Character {
  id: string;
  sourceId: string;
  age_range: string;
  available: boolean;
  clothing_style: string;
  ethnicity: string;
  eye_color: string;
  emotion?: Emotion;
  hair_color: string;
  hair_style: string;
  description: string;
  gender: Gender;
  title: string;
  character_outfit: string;
  character_source: string;
  character_id: string;
  favorite: boolean;
  image: string;
  video: string;
  pro: boolean;
  tailor_made: boolean;
  plans: PlanEnum[];
  user_id?: string;
  tags?: string[];
  type?: CharacterType;
  dictionary?: CharacterDictionary[];
  created_at: string;
  shot_type?: string;
  thumbnails?: {
    page: {
      image: string;
      video: string;
    };
    panel: {
      image: string;
      video: string;
    };
    modal: {
      image: string;
      video: string;
    };
    page_banner: {
      image: string;
      video: string;
    };
  };
}

export interface CharacterDictionary {
  local: string;
  url: string;
}
export interface BecomeCharacter {
  videos_per_month: number;
  use_case: string;
  role: string;
  type_of_business: string;
}

export enum CharacterType {
  all = "all",
  reals = "reals",
  mobile = "mobile",
  desk = "desk",
  webcam = "webcam",
  cinematic = "cinematic"
}

export enum CharacterFiltersProperties {
  type = "type",
  age = "age_range",
  hairStyle = "hair_style",
  clothing = "clothing_style",
  gender = "gender"
}

export interface CharacterFilters {
  age: string;
  clothing: string;
  gender: Gender;
  hairStyle: string;
  type: CharacterType[];
  pro: boolean;
  free: boolean;
  myAvatars: boolean;
  publicAvatars: boolean;
}

export const CharacterAll = "all";

export enum Emotion {
  Expressive = "Expressive",
  Smiling = "Smiling",
  Natural = "Natural",
  Neutral = "Neutral",
  Friendly = "Friendly",
  Motionless = "Motionless",
  Active = "Active",
  Energetic = "Energetic",
  Subtle = "Subtle"
}

export const defaultCharacterFilter = {
  free: true,
  myAvatars: true,
  publicAvatars: true,
  pro: true,
  gender: Gender.all,
  age: CharacterAll,
  clothing: CharacterAll,
  hairStyle: CharacterAll,
  type: [CharacterType.all]
};

export const getEmotionEmoji = (emotion?: string): string => {
  if (!emotion) {
    return "";
  }
  switch (emotion) {
    case Emotion.Neutral:
      return "🙂";
    case Emotion.Energetic:
      return "🤸";
    case Emotion.Active:
      return "💁";
    case Emotion.Motionless:
      return "😐";
    case Emotion.Friendly:
      return "😃";
    case Emotion.Natural:
    case Emotion.Subtle:
      return "😚";
    case Emotion.Expressive:
      return "😐";
    case Emotion.Smiling:
      return "😁";
    default:
      return "";
  }
};
