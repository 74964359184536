/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  H1_TextHeadline,
  H1_TextMiddle,
  H1_TextSmall
} from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled, { useTheme } from "styled-components";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import { H1_Input } from "app/components/_Infrastructure/design-system/input";
import { useIntl } from "react-intl";
import { useDebounce } from "@react-hook/debounce";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { presentersPageMessages } from "app/pages/presenters/messages";
import { charactersActions } from "app/store/slices/characters.slice";
import * as CharacterSelectors from "app/store/selectorsV2/character.selectors";
import { getAvatarsInProgress } from "app/store/selectorsV2/character.selectors";
import { fetchingStatus } from "app/utils/helpers";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import ConditionalRender from "app/components/common/ConditionalRender";
import { AnimatePresence, motion } from "framer-motion";
import { Character, CharacterType } from "app/types/character";
import PresenterModal from "app/pages/presenters/PresenterModal";
import PresentersFilters from "app/pages/presenters/PresentersFilters";
import EmptyState from "app/components/common/EmptyState";
import { avatarsActions } from "app/store/slices/avatars.slice";
import useModal, { ModalName } from "app/hooks/useModal";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { homePageMessages } from "app/pages/HomePage/messages";
import { FeatureFlag } from "app/types";
import PresentersPageSingleElement from "app/pages/presenters/PresentersPageSingleElement";
import { ThemeMode } from "app/utils/theme";

const MAX_BANNER_CHARACTERS = 3;
const BackgroundFlexColumn = styled(H1_FlexColumn)<{ $isLoading: boolean }>`
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray12)};
  min-height: ${({ $isLoading }) => ($isLoading ? "auto" : 0)};
`;
const HeaderFlexColumn = styled(H1_FlexColumn)<{ $collapse: boolean }>`
  background: linear-gradient(84deg, #7d73f4 -4.73%, #d8dce0 202.91%);
  padding: ${({ $collapse }) => ($collapse ? "20px" : "61px 80px 61px 35px")};
  border-radius: 9px;
  overflow: hidden;
`;
const HeaderFlexRow = styled(H1_FlexRow)<{ $scroll: number; $collapse: boolean }>`
  position: relative;
  opacity: ${({ $scroll, $collapse }) => ($collapse ? "0" : "calc(1 - " + $scroll + " / 280)")};
  transition-duration: 0.2s;
  transition-property: max-height, opacity, padding-bottom, height;
  max-height: ${({ $collapse }) => ($collapse ? "0" : "291px")};
  z-index: 3;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    > span {
      font-size: 1.5rem;
      line-height: 1.75rem;
    }
  }
`;

const CharacterBackgroundFlexColumn = styled(H1_FlexColumn)<{ $background?: string }>`
  overflow: hidden;
  border-radius: 8px;
  background: ${({ $background }) => $background || "inherit"};
`;

const CharacterImage = styled.img`
  object-fit: cover;
`;

const BorderedFlexRow = styled(H1_FlexRow)`
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 43px;
  border: 1px solid white;
  position: absolute;
  left: 13px;
  bottom: 38px;
  font-size: 8px;
  padding: 1px 5px;
`;

const NewReleasesText = styled(H1_TextSmall)`
  position: absolute;
  left: 13px;
  bottom: 8px;
`;

const BackgroundFillerSticky = styled.div`
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray12)};
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 3;
  height: 126px;
  margin-bottom: -126px;
`;

const SearchFlexColumn = styled(H1_FlexColumn)`
  top: 0;
  z-index: 3;
  position: sticky;
  margin-top: -127px;
  margin-bottom: 38px;
`;

const SearchIcon = styled.i`
  color: ${({ theme }) => theme.gray6};
  font-size: 11px;
  left: 18px;
  top: 11px;
  position: absolute;
  z-index: 3;
`;

const RoundSearch = styled(H1_Input)`
  border-radius: 43px;
  input {
    box-shadow: 0px 0.76644px 6.13149px 0px rgba(0, 0, 0, 0.25) inset;
    padding-left: 40px;
    height: 50px;
    border-radius: 43px;
    transition-property: box-shadow;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    @media (max-width: ${SMALL_SCREEN_PX}) {
      min-width: 300px;
    }
  }
`;

const WidthTextMiddle = styled(H1_TextMiddle)`
  max-width: 100%;
  padding: 0 10px;
`;

const BackgroundContainer = styled(H1_FlexRow)`
  background-color: ${({ theme }) => theme.gray2};
`;

const HoverFlexRow = styled(H1_FlexRow)`
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
`;

const BoxCharacterFlexColumn = styled(H1_FlexColumn)`
  border-radius: 10px;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray12)};
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    transform: scale(1.03);
    border: 1px solid ${({ theme }) => theme.gray6};
    ${HoverFlexRow} {
      opacity: 1;
      pointer-events: auto;
    }
  }
  &:active {
    box-shadow: none;
    transform: scale(0.97);
  }
`;

const PresentersPage = () => {
  const [selectedCharacter, setSelectedCharacter] = useState<string | undefined>(undefined);
  const [scrollTop, setScrollTop] = useState(0);
  const [searchQuery, setSearchQuery] = useDebounce<string>("", 500);
  const fullPageRef = useRef<HTMLDivElement>(null);
  const { formatMessage } = useIntl();
  const flags = useFlags();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state) => state.characters.filters);
  const charactersStatus = useAppSelector((state) => state.characters.charactersStatus);
  const top3PublicCharacters = useAppSelector((state) => {
    const chars = CharacterSelectors.getPublicCharactersByTag(state, "New");
    const sliced = CharacterSelectors.SlicePublicCharacterSize(state, MAX_BANNER_CHARACTERS, chars);
    return sliced;
  });
  const { openModal } = useModal();

  const publicCharacters = useAppSelector((state) =>
    CharacterSelectors.getFilteredCharactersByProperties(state, searchQuery, false)
  );

  const avatarsSessions = useAppSelector(getAvatarsInProgress);

  const isSearching = !!searchQuery;
  const isLoading = charactersStatus === fetchingStatus.loading;

  const newReleasesCharacters = useMemo(() => {
    if (top3PublicCharacters.length > 0) {
      return [
        {
          background: "linear-gradient(0deg, #FFE7CA -4.73%, #FFAF4D 202.91%)",
          text: formatMessage(presentersPageMessages.headerCharacterText1)
        },
        {
          background: "linear-gradient(0deg, #FAD4DE -4.73%, #EE7090 202.91%)",
          text: formatMessage(presentersPageMessages.headerCharacterText2)
        },
        {
          background: "linear-gradient(0deg, #8989FF -4.73%, #A2F8AE 202.91%)",
          text: formatMessage(presentersPageMessages.headerCharacterText3)
        }
      ];
    }
  }, [top3PublicCharacters]);

  useEffect(() => {
    dispatch(charactersActions.getCharactersListRequest());
    dispatch(avatarsActions.getAvatarsRequest(["in_progress"]));
  }, []);

  const handleScroll = (event: React.MouseEvent<HTMLDivElement>) => {
    setScrollTop(event.currentTarget.scrollTop);
  };

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const onBlurSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(analyticsEvents.presenterSearch({ value: e.target.value, source: "presentersPage" }));
  };

  const onClear = () => {
    setSearchQuery("");
  };

  const onClickSelectCharacter = (selectedCharacter: string) => {
    dispatch(analyticsEvents.selectPresenterPageCharacter({ selectedCharacter }));
    setSelectedCharacter(selectedCharacter);
  };

  const onCloseModal = () => {
    setSelectedCharacter(undefined);
  };

  const onResetAll = () => {
    dispatch(analyticsEvents.changePresenterPageFilters({ value: undefined, filter: "reset" }));
    dispatch(charactersActions.resetFilters());
    setSearchQuery("");
  };

  const openAvatarCreationModal = () => {
    openModal(ModalName.createVirtualTwin);
  };

  return (
    <BackgroundFlexColumn
      $isLoading={isLoading}
      flex="1"
      overflow={isLoading ? "visible" : "hidden auto"}
      onScroll={handleScroll}
      ref={fullPageRef}
    >
      <PresenterModal
        characterId={selectedCharacter}
        visible={!!selectedCharacter}
        onCloseModal={onCloseModal}
      />
      <BackgroundFillerSticky />
      {/* Header */}
      <HeaderFlexRow
        padding="0 45px"
        $collapse={isSearching}
        $scroll={scrollTop}
        flex="0 0 291px"
        margin="0 0 27px 0"
        height="291px"
        gap="14px"
        justify="space-between"
      >
        <HeaderFlexColumn
          $collapse={isSearching}
          flex="1 0 auto"
          margin="0 0 27px 0"
          height="291px"
        >
          <H1_FlexColumn height="95px" justify="center" width="400px">
            <H1_TextHeadline
              whiteSpace="normal"
              fontSize="34px"
              color={theme.gray1}
              fontWeight={700}
            >
              {formatMessage(presentersPageMessages.headerTitle)}
            </H1_TextHeadline>
            <H1_TextHeadline
              whiteSpace="normal"
              fontSize="34px"
              color={theme.gray1}
              fontWeight={700}
            >
              {formatMessage(presentersPageMessages.headerSubTitle)}
            </H1_TextHeadline>
          </H1_FlexColumn>
        </HeaderFlexColumn>
        {top3PublicCharacters.map((character: Character, index: number) => (
          <CharacterBackgroundFlexColumn
            key={character.id + index}
            position="relative"
            overflow="hidden"
            justify="flex-end"
            width="175px"
            height="291px"
            flex="0 0 175px"
            onClick={() => onClickSelectCharacter(top3PublicCharacters[index].id)}
            $background={newReleasesCharacters?.[index % MAX_BANNER_CHARACTERS]?.background}
          >
            <CharacterImage src={top3PublicCharacters[index].image} width={175} height={291} />
            <BorderedFlexRow justify="center" align="center">
              {newReleasesCharacters?.[index].text}
            </BorderedFlexRow>
            <NewReleasesText color={theme.gray1}>
              {formatMessage(presentersPageMessages.newReleases)}
            </NewReleasesText>
          </CharacterBackgroundFlexColumn>
        ))}
      </HeaderFlexRow>
      <SearchFlexColumn
        padding={isSearching || scrollTop > 280 ? "10px 0 0 45px" : "10px 0 0 80px"}
        position="sticky"
        width="100%"
        gap="15px"
      >
        <H1_FlexRow width="100%" height="50px" position="relative">
          <SearchIcon className="fa-regular fa-search" />
          <RoundSearch
            allowClear
            onChange={onChangeSearch}
            onBlur={onBlurSearch}
            onClear={onClear}
            initialValue={searchQuery}
            placeholder={formatMessage(presentersPageMessages.searchPlaceholder)}
            minWidth="444px"
            isFixedWidth
          />
        </H1_FlexRow>
      </SearchFlexColumn>
      <ConditionalRender condition={isLoading}>
        <H1_FlexColumn flex="1">
          <CircleLoader />
        </H1_FlexColumn>
      </ConditionalRender>
      <ConditionalRender condition={!isLoading}>
        <PresentersFilters scrollTop={scrollTop} />
        <H1_FlexColumn padding="0 45px 50px 177px" flex="1" position="relative">
          <H1_FlexRow
            flex="1 0 auto"
            padding={isSearching ? "57px 0 20px 0" : "17px 0 20px 0"}
            wrap="wrap"
            gap="14px"
            justify="flex-start"
          >
            <ConditionalRender
              condition={publicCharacters.length === 0 && avatarsSessions.length === 0}
            >
              <EmptyState
                text={formatMessage(presentersPageMessages.emptyText)}
                description={formatMessage(presentersPageMessages.emptyDescription)}
                icon="fal fa-user"
                withButton
                buttonText={formatMessage(presentersPageMessages.resetAll)}
                onClickButton={onResetAll}
              />
            </ConditionalRender>
            <ConditionalRender
              condition={publicCharacters.length > 0 || avatarsSessions.length > 0}
            >
              <AnimatePresence>
                <ConditionalRender condition={flags[FeatureFlag.showAddAvatarOnPresenters]}>
                  <motion.div
                    layout
                    key="addNew"
                    transition={{
                      default: {
                        duration: 0.5
                      }
                    }}
                  >
                    <BoxCharacterFlexColumn
                      align="center"
                      flex="0 0 auto"
                      width="232px"
                      height="215px"
                      gap="7px"
                      position="relative"
                      overflow="hidden"
                      onClick={openAvatarCreationModal}
                    >
                      <BackgroundContainer
                        width="100%"
                        height="164px"
                        justify="center"
                        align="center"
                      >
                        <H1_Icon icon="fa-solid fa-user-plus" color={theme.gray8} size="2rem" />
                      </BackgroundContainer>
                      <WidthTextMiddle textAlign="center" ellipsis>
                        {formatMessage(homePageMessages.fromOwnCharacter)}
                      </WidthTextMiddle>
                    </BoxCharacterFlexColumn>
                  </motion.div>
                </ConditionalRender>

                {/*present only on all*/}
                <ConditionalRender
                  condition={
                    !filters.type ||
                    !filters?.type?.length ||
                    filters.type.includes(CharacterType.all)
                  }
                >
                  {avatarsSessions.map((avatar, index) => {
                    return (
                      <motion.div
                        layout
                        key={avatar.id + index}
                        transition={{
                          default: {
                            duration: 0.5
                          }
                        }}
                      >
                        <BoxCharacterFlexColumn
                          align="center"
                          flex="0 0 auto"
                          width="232px"
                          height="215px"
                          gap="7px"
                          position="relative"
                          overflow="hidden"
                        >
                          <BackgroundContainer flex="4" height="164px" width="100%">
                            <CircleLoader size="164px" />
                          </BackgroundContainer>
                          <H1_FlexColumn flex="1">
                            <WidthTextMiddle textAlign="center">
                              {formatMessage(presentersPageMessages.progress)}
                              <H1_TextSmall
                                margin="0 0 0 5px"
                                lineHeight="1.575rem"
                                color={theme.gray7}
                                textAlign="center"
                                whiteSpace="normal"
                              >
                                {avatar.progress || 0}%
                              </H1_TextSmall>
                            </WidthTextMiddle>
                          </H1_FlexColumn>
                        </BoxCharacterFlexColumn>
                      </motion.div>
                    );
                  })}
                </ConditionalRender>
                {publicCharacters.map((character: Character, index) => {
                  return (
                    <motion.div
                      layout
                      key={character.id + index}
                      transition={{
                        default: {
                          duration: 0.5
                        }
                      }}
                    >
                      <PresentersPageSingleElement
                        characterId={character.id}
                        onClickSelectCharacter={onClickSelectCharacter}
                      />
                    </motion.div>
                  );
                })}
              </AnimatePresence>
            </ConditionalRender>
          </H1_FlexRow>
        </H1_FlexColumn>
      </ConditionalRender>
    </BackgroundFlexColumn>
  );
};

export default PresentersPage;
