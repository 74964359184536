import { useSortable } from "@dnd-kit/sortable";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled, { useTheme } from "styled-components";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import { useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import usePermissions from "app/hooks/usePermissions";
import ConditionalRender from "app/components/common/ConditionalRender";

const ContainerFlexRow = styled(H1_FlexRow)`
  z-index: 2;
  transition: 0.3s ease-in-out all;
`;
const GrabFlexRow = styled(H1_FlexRow)<{ $disabled: boolean; $isHover?: boolean }>`
  opacity: ${({ $disabled }) => ($disabled ? 0 : 1)};
  z-index: ${({ $disabled }) => ($disabled ? -1 : 2)};
  transition: 0.3s ease-in-out all;
  cursor: grab;
  color ${({ theme }) => theme.gray11};
  & i {
    cursor: grab;
  }
  &:hover {
    i {
      color ${({ theme }) => theme.gray11};
    }
  }
`;

interface SceneSortableItemProps {
  id: string;
  idx: number;
  isVisible?: boolean;
  isSelected?: boolean;
}
const SceneSortableItem = ({ id, idx, isVisible, isSelected }: SceneSortableItemProps) => {
  const { attributes, listeners, setNodeRef } = useSortable({ id: id });
  const theme = useTheme();
  const { sceneId } = useSelectedScene();
  const { isWriteWorkspaceAllow } = usePermissions();

  const scenesStatus = useAppSelector((state) => state.scenes.scenesStatus);
  const isSceneLoading = scenesStatus === fetchingStatus.loading;
  const isSceneSelected = sceneId === id;

  return (
    <ContainerFlexRow
      height="auto"
      margin={isSelected ? "20px 0 0 0" : "0"}
      align="center"
      position="relative"
      gap="14px"
      ref={setNodeRef}
    >
      <GrabFlexRow
        justify="center"
        width="27px"
        align="center"
        {...attributes}
        {...listeners}
        $isHover={isVisible || isSceneSelected}
        $disabled={isSceneLoading || !isWriteWorkspaceAllow}
      >
        <ConditionalRender condition={isVisible || isSceneSelected}>
          <H1_Icon color={theme.gray7} icon="fas fa-grip-dots-vertical" isCursorPointer />
        </ConditionalRender>
        <ConditionalRender condition={!isVisible && !isSceneSelected}>{idx}</ConditionalRender>
      </GrabFlexRow>
    </ContainerFlexRow>
  );
};

export default SceneSortableItem;
