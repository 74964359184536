import ConditionalRender from "app/components/common/ConditionalRender";
import { Tooltip } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { CloneVoiceStatus, Draft, FeatureFlag, PatchOperation, Voice, VoiceType } from "app/types";
import styled, { css, keyframes, useTheme } from "styled-components";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { draftsActions as draftsActionsV2 } from "app/store/slices/drafts.slice";
import { scenesActions } from "app/store/slices/scenes.slice";
import { voicesActions } from "app/store/slices/voices.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { messages } from "app/components/editor/sideDrawers/messages";
import { useIntl } from "react-intl";
import { languageTranslationHelper } from "app/utils/iso6393To1";
import useUpgradeModal from "app/hooks/useUpgradeModal";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import usePermissions from "app/hooks/usePermissions";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import DevModePopover from "app/components/DevModePopover";
import { fetchingStatus, replaceFlagSeparator } from "app/utils/helpers";
import { useFlags } from "launchdarkly-react-client-sdk";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import _ from "lodash";
import { marqueeAnimation } from "app/components/common/MarqueeTextBox";
import { Chip } from "@nextui-org/chip";
import { Button, Card, CardBody } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

const StyledIconBackground = styled(H1_FlexRow)<{ $isVisible?: boolean }>`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.gray5};
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  &&& {
    ${({ $isVisible }) =>
      !!$isVisible &&
      css`
        opacity: 1;
      `};
  }
`;

const StyledVoiceSettingsIcon = styled(Button)`
  transition: transform 0.2s ease-in;
  color: ${(props) => props.theme.gray7};
  span {
    color: ${(props) => props.theme.gray7};
  }
  height: 28px;
  min-width: 28px;
  gap: 12px;
  &:hover {
    transform: scale(1.1);
  }
`;
const StyledPlayCircleFilled = styled.i`
  cursor: pointer;
  transition: all 0.3s ease-in;
  font-size: 12px;
  color: ${(props) => props.theme.gray8};
  &:hover {
    color: ${(props) => props.theme.gray7};
  }
`;

const StyledPauseCircleFilled = styled.i`
  font-size: 12px;
  color: ${(props) => props.theme.gray8};
  &:hover {
    color: ${(props) => props.theme.gray7};
  }
`;

const StyledCard = styled(Card)<{ $isShadow: boolean }>`
  height: 69px;
  flex: 0 0 auto;
  width: 334px;
  border-radius: 16px;
  outline: none !important;
  margin-left: 2px;
  margin-top: 2px;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
  &&&:hover {
    --tw-shadow: var(--nextui-box-shadow-small);
    --tw-shadow-colored: var(--nextui-box-shadow-small);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
      var(--tw-shadow);
  }
  &&&:active {
    --tw-shadow: var(--nextui-box-shadow-none);
    --tw-shadow-colored: var(--nextui-box-shadow-none);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
      var(--tw-shadow);
  }
  ${({ $isShadow }) =>
    $isShadow &&
    css`
      --tw-shadow: var(--nextui-box-shadow-small);
      --tw-shadow-colored: var(--nextui-box-shadow-small);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
    `};
`;

const StyledSelectionElementFlexRow = styled(H1_FlexRow)<{
  isSelected?: boolean;
  sticky?: boolean;
}>`
  border-radius: 16px;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  border: 1px solid ${({ theme }) => theme.gray2};
  &&:hover {
    background-color: ${(props) => props.theme.gray2};
  }
  ${({ isSelected, sticky, theme }) =>
    css`
      border: ${isSelected && !sticky
        ? `1px solid ${theme.blue4}`
        : sticky
        ? `1px solid ${theme.gray3}`
        : ""};
    `}

  ${StyledIconBackground} {
    opacity: 0;
  }
  &:hover ${StyledIconBackground} {
    opacity: 1;
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
`;

const Marquee = styled(H1_FlexRow)<{
  $isMarqueeNeeded: boolean;
  $hoverVoiceId: boolean;
  $width: number;
  $containerWidth: string;
}>`
  display: inline-flex;
  animation: none;
  ${({ $isMarqueeNeeded, $hoverVoiceId, $width, $containerWidth }) =>
    $isMarqueeNeeded &&
    $hoverVoiceId &&
    css`
      animation: ${marqueeAnimation($width, $containerWidth)} 10s linear infinite};
    `}
`;

const MarqueeContainer = styled.div<{ $isShadowOnSides: boolean }>`
  overflow: hidden;
  white-space: nowrap;
  max-width: 184px;
  ${({ $isShadowOnSides }) =>
    $isShadowOnSides &&
    css`
      mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);
    `};
`;

const StyledTag = styled(H1_FlexRow)<{ $hoverVoiceId: boolean }>`
  justify-content: center;
  align-items: center;
  border-radius: 43px;
  background-color: ${({ theme, $hoverVoiceId }) => ($hoverVoiceId ? theme.gray4 : theme.gray3)};
  color: ${({ theme }) => theme.gray7};
  font-size: 10px;
  lien-height: 10px;
  padding: 0 10px;
  height: 18px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
`;

const Dot = styled.div`
  border-radius: 50%;
  height: 4px;
  width: 4px;
  line-height: 20px;
  margin: auto 0;
  background-color: ${({ theme }) => theme.gray8};
  flex: 0 0 4px;
`;

const StyledAvatar = styled.img`
  border: 1px solid ${(props) => props.theme.gray3};
  border-radius: 2px;
  width: 45px;
  height: 35px;
  object-fit: cover;
  ${({ $isSelected, $isSticky }: { $isSelected: boolean; $isSticky: boolean }) =>
    $isSelected &&
    $isSticky &&
    css`
      animation: ${pulse} 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    `}
`;

const CloneVoiceBackground = styled(H1_FlexRow)`
  background: ${(props) => props.theme.blue1};
  flex-shrink: 0;
  border-radius: 2px;
`;

const StyledChip = styled(Chip)`
  text-transform: uppercase;
  color: white;
  height: 20px;
  span {
    font-size: 11px;
  }
`;

const Icon = styled.i<{ $hoverVoiceId?: boolean }>`
  font-size: 12px;
  color: ${({ theme }) => theme.gray7};
  padding: 3px;
  background-color: ${({ theme, $hoverVoiceId }) => ($hoverVoiceId ? theme.gray4 : theme.gray3)};
  border-radius: 50%;
`;

const CrownIconContainer = styled(H1_FlexRow)`
  top: 10px;
  left: 7px;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.21));
  background-color: ${({ theme }) => theme.gray3};
  z-index: 3;
`;

export interface SelectedVoiceElementProp {
  item: Voice;
  isSelected: boolean;
  isPlaying: boolean;
  isPreviewItem: boolean;
  isLoadingVoice: boolean;
  isSticky?: boolean;
  onClickVoicePlay: (e: React.MouseEvent, item: Voice) => void;
  onChangePreview?: (item: Voice) => void;
  onClickVoiceSettingsIcon?: (e: React.MouseEvent) => void;
  assetKey: string;
}

const SelectedVoiceElement = ({
  item,
  isSelected,
  isPlaying,
  isPreviewItem,
  isLoadingVoice,
  isSticky,
  onClickVoicePlay,
  onChangePreview,
  onClickVoiceSettingsIcon,
  assetKey
}: SelectedVoiceElementProp) => {
  const [isMarqueeNeeded, setIsMarqueeNeeded] = useState(false);
  const [hoverVoiceId, setHoverVoiceId] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const showPaymentModal = useUpgradeModal();
  const flags = useFlags();

  const { currentPlan } = useCurrentPlan();
  const { isWriteWorkspaceAllow } = usePermissions();

  const draft: Draft = useAppSelector((state) => state.drafts.currentDraft);
  const sceneId = useAppSelector((state) => state.scenes.selectedSceneId);
  const currentDraft = useAppSelector((state) => state.drafts.currentDraft) as Draft;
  const draftGlobalCharacterStatus = useAppSelector(
    (state) => state.drafts.draftGlobalCharacterStatus
  );

  const isDraftGlobalCharacterLoading = draftGlobalCharacterStatus === fetchingStatus.loading;
  const isApplyAllScenes = currentDraft?.global_character;
  const draftId = draft?.id as string;
  const flagSvg = replaceFlagSeparator(item.local);

  const languageText: string = useMemo(() => {
    return languageTranslationHelper(item.local, intl);
  }, [item]);

  useEffect(() => {
    if (containerRef.current && contentRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const contentWidth = contentRef.current.offsetWidth;
      setIsMarqueeNeeded(contentWidth > containerWidth);
    }
  }, []);

  const isPro = (voice: Voice): boolean => {
    return !!voice.plans.length && !voice.plans.includes(currentPlan);
  };

  const chooseVoice = (currentItem: Voice) => {
    if (
      !isWriteWorkspaceAllow ||
      isSticky ||
      (item.type === VoiceType.cloned && item.clone_status !== CloneVoiceStatus.done)
    ) {
      return;
    }
    const { id, display_name, plans } = currentItem;
    const gender = currentItem.gender || "";
    const pro = isPro(currentItem);
    dispatch(
      googleEvents.chooseVoice({
        id,
        name: display_name,
        gender,
        pro
      })
    );

    if (pro) {
      showPaymentModal({
        source: "upgrade_voices_drawer",
        upgradeText: intl.formatMessage(messages.voiceDrawerPaymentUpgrade),
        requestedPlans: plans
      });
    } else {
      onVoiceChange(id);
      onChangePreview?.(currentItem);
    }
  };

  const onVoiceChange = (voiceId: string) => {
    if (isDraftGlobalCharacterLoading) {
      return;
    }
    const op = [
      { op: "replace", path: `attributes.voice.${assetKey}.voice_id`, value: voiceId }
    ] as PatchOperation[];

    if (isApplyAllScenes) {
      dispatch(
        draftsActionsV2.patchDraftRequest({
          draftId,
          operations: op,
          skipRePreview: true
        })
      );
    } else if (!isApplyAllScenes) {
      dispatch(
        scenesActions.patchSceneRequest({
          draftId,
          sceneId: sceneId as string,
          operations: op,
          skipRePreview: true
        })
      );
    }

    // Stop the Scene Listen feature if its played
    dispatch(voicesActions.cleanFooterAudio());
  };

  const onMouseEnter = () => {
    setHoverVoiceId(true);
  };

  const onMouseLeave = () => {
    setHoverVoiceId(false);
  };

  return (
    <StyledCard shadow="none" isPressable={!isSticky} $isShadow={isSticky}>
      <CardBody className="overflow-visible p-0 static">
        <StyledSelectionElementFlexRow
          position="relative"
          justify="space-between"
          height="69px"
          padding="10px"
          width="334px"
          align="center"
          gap="10px"
          flex="0 0 auto"
          isSelected={isSelected}
          key={item.id}
          onClick={() => chooseVoice(item)}
          sticky={isSticky}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <ConditionalRender condition={isPro(item)}>
            <CrownIconContainer
              width="11px"
              height="11px"
              position="absolute"
              justify="center"
              align="center"
            >
              <H1_Icon color={theme.orange3} size="7px" icon="fa-solid fa-crown" />
            </CrownIconContainer>
          </ConditionalRender>
          <H1_FlexRow align="center" gap="15px" overflow="hidden" position="relative">
            <ConditionalRender condition={item.type !== VoiceType.cloned}>
              <StyledAvatar
                $isSticky={isSticky || false}
                $isSelected={isSelected}
                src={`https://flagcdn.com/${flagSvg}.svg`}
              />
            </ConditionalRender>
            <ConditionalRender condition={item.type === VoiceType.cloned}>
              <CloneVoiceBackground width="45px" height="35px" align="center" justify="center">
                <ConditionalRender condition={item.clone_status === CloneVoiceStatus.processing}>
                  <i className="fa-solid fa-spinner fa-spin" style={{ color: "white" }} />
                </ConditionalRender>
                <ConditionalRender condition={item.clone_status === CloneVoiceStatus.done}>
                  <i className="fa-solid fa-microphone" style={{ color: theme.blue4 }} />
                </ConditionalRender>
              </CloneVoiceBackground>
            </ConditionalRender>

            <H1_FlexColumn flex="0 0 auto" gap="6px">
              <H1_FlexRow align="center" gap="5px">
                <ConditionalRender condition={!!item.tags?.length}>
                  <StyledChip size="sm" color="warning">
                    {item.tags?.[0]}
                  </StyledChip>
                </ConditionalRender>
                <H1_TextXs
                  lineHeight="15px"
                  fontWeight={isSelected ? 600 : 500}
                  color={theme.gray8}
                >
                  {item.display_name} -
                </H1_TextXs>
                <H1_TextXs
                  lineHeight="15px"
                  fontWeight={isSelected ? 600 : 500}
                  color={theme.gray8}
                >
                  {languageText}
                </H1_TextXs>
                <ConditionalRender condition={flags[FeatureFlag.voiceProvider]}>
                  <Dot />
                  <H1_TextXs>{item.provider}</H1_TextXs>
                </ConditionalRender>
              </H1_FlexRow>
              <H1_FlexRow gap="5px" align="center">
                <MarqueeContainer ref={containerRef} $isShadowOnSides={isMarqueeNeeded}>
                  <Marquee
                    align="center"
                    gap="10px"
                    ref={contentRef}
                    $hoverVoiceId={hoverVoiceId}
                    $isMarqueeNeeded={isMarqueeNeeded}
                    $width={
                      contentRef.current ? (contentRef.current as HTMLDivElement).offsetWidth : 0
                    }
                    $containerWidth={`${containerRef.current?.offsetWidth || 0}px`}
                  >
                    <ConditionalRender condition={item.pause_supported}>
                      <Tooltip title={intl.formatMessage(messages.voiceSupportPause)}>
                        <Icon $hoverVoiceId={hoverVoiceId} className="far fa-clock" />
                      </Tooltip>
                    </ConditionalRender>
                    {item.tone &&
                      item.tone.length > 0 &&
                      item.tone.map((tone, index) => (
                        <StyledTag $hoverVoiceId={hoverVoiceId} key={index}>
                          {_.capitalize(tone).replace("_", " ")}
                        </StyledTag>
                      ))}
                    <ConditionalRender condition={!!item.age}>
                      <StyledTag $hoverVoiceId={hoverVoiceId}>
                        {_.capitalize(item.age).replace("_", " ")}
                      </StyledTag>
                    </ConditionalRender>
                    {item.use_case &&
                      item.use_case.length > 0 &&
                      item.use_case.map((useCase, index) => (
                        <StyledTag $hoverVoiceId={hoverVoiceId} key={index}>
                          {_.capitalize(useCase).replace("_", " ")}
                        </StyledTag>
                      ))}
                  </Marquee>
                </MarqueeContainer>
              </H1_FlexRow>
            </H1_FlexColumn>
          </H1_FlexRow>
          <H1_FlexRow align="center" gap="10px" flex="0 0 58px" justify="flex-end">
            <H1_FlexRow height="18px" align="center">
              <ConditionalRender condition={isSticky && flags[FeatureFlag.voiceSettings]}>
                <StyledVoiceSettingsIcon
                  onClick={onClickVoiceSettingsIcon}
                  variant="light"
                  isIconOnly
                  startContent={<i className="far fa-sliders-simple" />}
                />
              </ConditionalRender>
              <ConditionalRender
                condition={
                  (hoverVoiceId &&
                    !isSticky &&
                    ((isPreviewItem && !isPlaying && !isLoadingVoice) || !isPreviewItem)) ||
                  (isSticky && !isPlaying && !isLoadingVoice)
                }
              >
                <StyledIconBackground
                  $isVisible={isSticky}
                  onClick={(e) => onClickVoicePlay(e, item)}
                >
                  <StyledPlayCircleFilled className="fas fa-play" />
                </StyledIconBackground>
              </ConditionalRender>
              <ConditionalRender condition={isPreviewItem && isLoadingVoice}>
                <StyledIconBackground $isVisible={isSticky || isLoadingVoice}>
                  <H1_Icon
                    size="10px"
                    color={theme.gray8}
                    icon="fa-solid fa-spinner-third fa-spin"
                  />
                </StyledIconBackground>
              </ConditionalRender>
              <ConditionalRender condition={isPreviewItem && isPlaying && !isLoadingVoice}>
                <StyledIconBackground
                  $isVisible={isSticky || isPlaying}
                  onClick={(e) => onClickVoicePlay(e, item)}
                >
                  <StyledPauseCircleFilled className="fas fa-pause" />
                </StyledIconBackground>
              </ConditionalRender>
            </H1_FlexRow>
            <DevModePopover
              placement="left"
              value={{ voice_id: item.id }}
              path={"root"}
              position={"relative"}
            />
          </H1_FlexRow>
        </StyledSelectionElementFlexRow>
      </CardBody>
    </StyledCard>
  );
};

export default SelectedVoiceElement;
